import React from 'react';

import Loading from './Loading.js';
import LoggedOutView from './LoggedOutView.js';
import { fetchLoginStatus } from './SecurityUtils.js';

export default function CorgillogicalMyAccountView({next, ...props}) {
    const [name, setName] = React.useState('');
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        fetchLoginStatus((loggedIn, name) => {
            setIsLoaded(true);
            setIsLoggedIn(loggedIn);
            setName(name);
        });
    }, []);

    if (!isLoaded) {
        return <Loading />;
    }

    if (!isLoggedIn) {
        return <LoggedOutView next={next} />;
    }
    
    return (
        <>
            <div>
                Logged In as {name}. {' '}
                <a href={'/logout/?next=' + next}>Logout</a>
            </div>
            <br />
            {props.children}
        </>        
    );
}