import './PuzzleToast.css';

import { Toast, ToastContainer } from 'react-bootstrap';

export default function PuzzleToast({
    show,
    onClose,
    text = 'Results copied to clipboard!',
}) {
    return (
        <ToastContainer className="puzzleToastContainer" position="top-center">
            <Toast
                className="puzzleToast"
                onClose={onClose}
                show={show}
                delay={2000}
                autohide>
                <Toast.Body>{text}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}
