import React from "react";
import { Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { classes } from '../common/Utils.js';
import { Checkmark } from '../common/Checkmark.js';
import { PackViewFooter } from '../common/Footer.js';
import Loading from '../common/Loading.js';

import { SokobanLevels } from './SokobanLevel.js';
import SokobanGrid from './SokobanGrid.js';

function SokobanLevelViewItem({gridData}) {
    let par = gridData.par();
    let parText = par === 0 ? ' - ' : (par + ' steps');
    let personalBest = gridData.personalBest ? (gridData.personalBest + ' steps') : ' - ';
    let solved = gridData.personalBest > 0;

    let previewClass = classes({
        sokobanLevelPreview: true,
        solved: solved,
    });
    return (
        <>
            <div className={previewClass}>
                <SokobanGrid
                    grid={gridData.getPlayGrid()}
                    gridData={gridData}
                />
                { solved ?
                    <div className="sokobanLevelOverlay">
                        <Checkmark
                            color={gridData.personalBest <= par ? 'green' : 'yellow'}
                        />
                    </div> : null
                }
            </div>
            <Carousel.Caption>
                <h3>{gridData.getDesc()}</h3>
                Best: {parText}
                <div className="spacer" />
                Personal Best: {personalBest}
            </Carousel.Caption>
        </>
    );
}

function SokobanLevelGroup({levels, desc}) {
    const navigate = useNavigate();
    let handleLevelOnClick = (level) => {
        return () => navigate('/sokoban?level=' + level.getName());
    }

    if (levels.length === 0) {
        return null;
    }

    return (
        <div className="sokobanPack">
            <h2 className="sokobanHeader">
                {SokobanLevels.getDesc(desc)}
            </h2>
            <Carousel variant="dark" interval={10000}>
                {
                    levels.map((level) =>
                        level.expired ? null :
                        <Carousel.Item>
                            <div onClick={handleLevelOnClick(level)}>
                                <SokobanLevelViewItem gridData={level} />
                            </div>
                        </Carousel.Item>
                    )
                }
            </Carousel>
        </div>
    );
}

export default function SokobanLevelView() {
    const [isLoaded, setIsLoaded] = React.useState(false);
    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        fetch('/sokoban/fetch/')
            .then(res => res.json())
            .then(
                  (payload) => {
                      SokobanLevels.install(payload);
                      setIsLoaded(true);
                  },
                  (error) => {
                      SokobanLevels.installOfflineLevels();
                      setIsLoaded(true);
                  }
            )
    }, []);

    if (!isLoaded) {
        return <Loading />;
    }

    let levelGroups = {};
    // This forces the ordering for some groups
    for (let g in SokobanLevels.groupDesc) {
        levelGroups[g] = [];
    }
    let allLevels = SokobanLevels.getAllLevels();
    for (let levelName in allLevels) {
        let level = allLevels[levelName];
        let group = level.getGroup();
        if (!(group in levelGroups)) {
            levelGroups[group] = [];
        }
        levelGroups[group].push(level);
    }
    return (
        <div className="sokobanLevelCarousel">
            {
                Object.entries(levelGroups).map(
                    ([group, levels]) =>
                        <SokobanLevelGroup levels={levels} desc={group} />
                )
            }
            <PackViewFooter />
        </div>
    );
}
