function arrow(di, dj) {
    if (dj === -1) return '\u{2190}';
    if (di === -1) return '\u{2191}';
    if (dj === 1) return '\u{2192}';
    if (di === 1) return '\u{2193}';
    return '';
}

export default function renderSolution(sol, height) {
    let solStr = '';
    for (const step of sol) {
        solStr += String.fromCharCode(step[1] + 65) + (height - step[0]);
        solStr += arrow(step[2], step[3]);
    }
    return solStr;
}
