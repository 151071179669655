// some constants for grid specs
// 0: empty cell 1: block 2: flag 3: goal
export const C = 0;
export const B = 1;
export const F = 2;
export const G = 3;
export const BG = 4;

export const GOAL_EMOJI = '\u{1F356}';
export const FLAG_EMOJI = '\u{1F415}';

export function toUnicode(b) {
    switch (b) {
    case B:
    case BG: // this is a block on top of a goal
        return '\u2B1B'; // large black square
    case F:
        return '\u{1F7E5}'; // large red square
    case G:
        return '\u{1F7E9}'; // large green square
    case C:
    default:
        return '\u2B1C'; // large white square
    }
}
