import { Container } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';

import CommonNavbar from '../common/CommonNavbar.js';

import Home from './Home.js';
import NotFound from './NotFound.js';
import PrivacyPolicy from './PrivacyPolicy.js';

export default function HomeAppRouter({lol=0}) {
    return (
        <div>
            <CommonNavbar title="Corgillogical" />

            <div className="AppBody">
            <Container className="AppContainer">

                <Routes>
                    <Route path='*' element={<NotFound />} />

                    <Route index element={<Home />} />
                    <Route path="privacy" element={<PrivacyPolicy />} />
                </Routes>

            </Container>
            </div>
        </div>
    );
}