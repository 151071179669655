import React from 'react';

import PayPalDonationButton from './PayPal.js';


export function PackViewFooter() {
    return (
        <div className="puzzleFooter">
            More puzzles are being developed.
            Contact <a href="mailto:corgillogical@gmail.com">Cocoa</a> if
            you have any feedback or questions!
            You may also <PayPalDonationButton /> to keep puzzles coming!
        </div>
    );
}

export function ResultViewFooter() {
    return (
        <div className="puzzleFooter">
            Like puzzles? Follow <a href="https://www.instagram.com/corgillogical" target="_blank">corgillogical</a> for more!
        </div>
    );
}
