import React from 'react';
import { Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { postWithToken } from '../common/SecurityUtils.js';
import Loading from '../common/Loading.js';
import PuzzleToast from '../common/PuzzleToast.js';
import ResultModal from '../common/ResultModal.js';

import { SokobanLevels } from './SokobanLevel.js';
import SokobanLeaderboard from './SokobanLeaderboardView.js';
import SokobanGameBoard from './SokobanGameBoard.js';


export default function Sokoban({level = 0}) {
    let setSearchParams = useSearchParams()[1];
    const getInitialLevel = () => {
        if (level) {
            return level;
        }
        // Grab a suitable default level.
        return SokobanLevels.getInitialLevel();
    };

    const [showLeaderboard, setShowLeaderboard] = React.useState(false);
    const [updateTime, setUpdateTime] = React.useState(Date.now());
    const [showShareToast, setShowShareToast] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [result, setResult] = React.useState(null);
    const [stepCount, setStepCount] = React.useState(0);
    const [currLevel, setCurrLevel] = React.useState(getInitialLevel);
    const [selectedLevel, setSelectedLevel] = React.useState(getInitialLevel);
    const [gridData, setGridData] = React.useState(null);
    const [restartCount, setRestartCount] = React.useState(0);

    const setInitialGridState = (level) => {
        let gridClass = SokobanLevels.get(level);
        if (gridClass === null) {
            return;
        }

        setGridData(gridClass);
    };

    const handleShowLeaderboardClick = (event) => {
        setShowLeaderboard(true);
	// unfocus the button so key presses won't show the top score again
	event.target.blur();        
    };

    const handleRestartLevel = React.useCallback((level) => {
        setGridData(null);
	setInitialGridState(level);
	setCurrLevel(level);
        setResult(null);
        setRestartCount(restartCount + 1);
    }, [restartCount]);

    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        fetch('/sokoban/fetch/')
            .then(res => res.json())
            .then(
                  (payload) => {
                      SokobanLevels.install(payload);

                      let initLevel = getInitialLevel();
                      setSelectedLevel(initLevel);
                      setCurrLevel(initLevel);
                      handleRestartLevel(initLevel);

                      setIsLoaded(true);
                  },
                  (error) => {
                      SokobanLevels.installOfflineLevels();

                      let initLevel = getInitialLevel();
                      setSelectedLevel(initLevel);
                      setCurrLevel(initLevel);
                      handleRestartLevel(initLevel);

                      setIsLoaded(true);
                  }
            )
    }, []);

    const handleRestartLevelEvent = React.useCallback((event) => {
        handleRestartLevel(selectedLevel);
	// unfocus the button so key presses won't repeatedly trigger restart
	event.target.blur();
        // TODO: only update if level changed
        setSearchParams({level: selectedLevel});
    }, [selectedLevel, setSearchParams, handleRestartLevel]);

    const handleLevelChange = (event) => {
	setSelectedLevel(event.target.value);
    };

    const handleShareLevel = React.useCallback(() => {
        let message = ''
        if (result === true) {
            message = 'I solved this sokoban puzzle in ' + stepCount +' steps, can you?';
        } else {
            message = 'Can you solve this sokoban puzzle?';
        }

        message += '\n\n' + gridData.getUnicodeRepresentation();
        message += '\n' + gridData.getURL();
        navigator.clipboard.writeText(message);
        setShowShareToast(true);
    }, [result, stepCount, gridData]);

    const renderShareButton = () => {
        if (gridData === null) {
            return null;
        }

        return (
            <>
                {' '}
                <Button
                    className="footerButton"
                    variant="success"
                    onClick={handleShareLevel}>
                    Share
                </Button>
            </>
        );
    }

    const renderFooter = () => {
        let allLevels = Object.values(SokobanLevels.getAllLevels()).filter(l => !l.expired);
	return (
	    <div>
                <select
                    className="levelSelector"
                    name="level"
                    onChange={handleLevelChange}
                    value={selectedLevel}>
                    {
                        allLevels.map(
                            (level) =>
                                <option 
                                    key={'level_' + level.getName()}
                                    value={level.getName()}>
                                    {level.getDesc()}
                                </option>
                        )
                    }
                </select>

	        <Button
                    className="footerButton"
                    variant="primary"
	            onClick={handleRestartLevelEvent}>
                    {currLevel !== selectedLevel ? 'Switch Level' : 'Restart'}
	        </Button>

                {renderShareButton()}

                {' '}
	        <Button
                    className="footerButton"
                    variant="light"
                    onClick={handleShowLeaderboardClick}>
                    Top Scores
	        </Button>

	    </div>
	);
    };

    const handleGameWon = React.useCallback((moves) => {
        setResult(true);
        setStepCount(moves.length);
        let data = {
            moves: moves,
            level: currLevel,
        };

        postWithToken('/sokoban/submit/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    for (let l in res['updates']) {
                        let updatedLevel = SokobanLevels.get(l);
                        if (!updatedLevel) {
                            continue;
                        }
                        updatedLevel.update(res['updates'][l]);
                    }
                    // update the time to trigger leaderboard refresh
                    setUpdateTime(Date.now());
                },
                (error) => {
                    console.log(error);
                },
            );
    }, [currLevel]);

    const handleGameOver = React.useCallback((moves) => {
        setResult(false);
    }, []);;


    if (!isLoaded) {
        return <Loading />;
    }

    if (gridData === null) {
        return (
            <div>
                <h2 className="sokobanHeader">
                    This level does not exist, please select another one.
                </h2>
                {renderFooter()}
            </div>
        );
    }

    if (gridData.expired) {
        return (
            <div>
                <h2 className="sokobanHeader">
                    Sorry, this level has expired and is not currently available.
                </h2>
                {renderFooter()}
            </div>
        );
    }

    return (
        <div>
            <PuzzleToast
                onClose={() => setShowShareToast(false)}
                show={showShareToast}
            />

            <ResultModal result={result} winText="Solved!" loseText="Try Again!" />

            <SokobanLeaderboard
                puzzleID={currLevel}
                modal={true}
                show={showLeaderboard}
                onHide={() => setShowLeaderboard(false)}
                updateTime={updateTime}
            />

            <SokobanGameBoard
                key={'grid_' + gridData.getName()}
                gridData={gridData}
                onGameWon={handleGameWon}
                onGameLost={handleGameOver}
                onRestart={handleRestartLevelEvent}
                restartCount={restartCount}
            />

            {renderFooter()}
        </div>
    );
}
