import React from 'react';
import { Table } from 'react-bootstrap';

import { Checkmark } from '../common/Checkmark.js';
import Loading from '../common/Loading.js';

function CorgillogicalPuzzleListRow({data}) {
    const puzzle = data.puzzle;

    let packURL = '/puzzle/?pack=' + puzzle.group.group_id;

    return (
        <tr>
            <td>
                <a href={packURL}>{puzzle.group.name}</a>
            </td>
            <td>
                {
                    puzzle.invisible ? puzzle.desc :
                        <a href={packURL + '&puzzle_id='+ puzzle.puzzle_id}>
                            {puzzle.name}
                        </a>
                }
            </td>
            <td>
                {data.solved && <Checkmark color="green" alt={'Puzzle solved!'} />}
            </td>
        </tr>
    );
}


export default function CorgillogicalAccountView({playerID = null}) {
    const [name, setName] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [puzzleData, setPuzzleData] = React.useState({});

    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        fetch('/puzzle/fetch_account/' + (playerID ? '?player_id=' + playerID : ''))
            .then(res => res.json())
            .then(
                  (payload) => {
                      setIsLoaded(true);
                      if (!payload.playerid) {
                          return;
                      }

                      setName(payload.name);
                      setPuzzleData(payload.puzzle_data)
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, [playerID]);

    if (!isLoaded) {
        return <Loading />;
    }

    if (name === null) {
        return (
            <h2 className="sokobanHeader">
                Player not found
            </h2>
        );
    }

    return (
        <div>
            <h2 className="sokobanHeader">
               Confuzzled Corgi: {name}
            </h2>
            <Table striped hover size="sm" className="sokobanListTable">
                <thead>
                    <tr>
                        <th>Group</th>
                        <th>Puzzle</th>
                        <th>Solved</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(puzzleData).map(([pname, pdata]) =>
                        <CorgillogicalPuzzleListRow key={'puzzle_row_' + pname} data={pdata} />
                    )}
                </tbody>
            </Table>
        </div>
    );
}
