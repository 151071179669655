import React from 'react';
import { Container } from 'react-bootstrap';

import Loading from '../common/Loading.js';

import QuizDetailView from './QuizDetailView.js';
import QuizListView from './QuizListView.js';

export default function QuizView({quizID}) {
    return (
        <Container className="eventDetailContainer">
            {
                quizID
                    ? <QuizDetailView quizID={quizID} />
                    : <QuizListView />
            }
        </Container>
    );
}
