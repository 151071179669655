import React from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import { Checkmark } from '../common/Checkmark.js';
import Loading from '../common/Loading.js';

import renderSolution from './SokobanSolution.js';


function SokobanPuzzleListRow({data}) {
    const puzzle = data.puzzle;

    const renderPersonalSolution = () => {
        if (!data.solved) {
            return <span>-</span>;
        }

        let stepsMsg = <span>{data.steps + ' steps'}</span>;
        if (!data.solution) {
            return stepsMsg;
        }

        return (
            <OverlayTrigger overlay={
                <Tooltip>{renderSolution(data.solution, puzzle.grid.length)}</Tooltip>
            }>
                {stepsMsg}
            </OverlayTrigger>
        );
    };

    let best = data.solved && (data.steps <= puzzle.bestSolution);
    return (
        <tr>
            <td>{puzzle.group}</td>
            <td>
                {
                    puzzle.invisible ? puzzle.desc :
                        <a href={'/sokoban?level=' + puzzle.name}>
                            {puzzle.desc}
                        </a>
                }
            </td>
            <td>{renderPersonalSolution()}</td>
            <td>{puzzle.bestSolution > 0 ? (puzzle.bestSolution + ' steps') : ' - '}</td>
            <td>
                {
                    data.solved ?
                        <Checkmark
                            color={best ? 'green' : 'yellow'}
                            alt={best ? 'Best Score!' : 'Try to improve your score!'}
                        /> :
                        null
                }
            </td>
        </tr>
    );
}

export default function SokobanAccountView({playerID = null}) {
    const [name, setName] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [puzzleData, setPuzzleData] = React.useState({});

    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        fetch('/sokoban/fetch_account/' + (playerID ? '?player_id=' + playerID : ''))
            .then(res => res.json())
            .then(
                  (payload) => {
                      setIsLoaded(true);
                      if (!payload.playerid) {
                          return;
                      }

                      setName(payload.name);
                      setPuzzleData(payload.puzzle_data)
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, [playerID]);

    if (!isLoaded) {
        return <Loading />;
    }

    if (name === null) {
        return (
            <h2 className="sokobanHeader">
                Player not found
            </h2>
        );
    }

    return (
        <div>
            <h2 className="sokobanHeader">
                Sokoban Operator: {name}
            </h2>
            <Table striped hover size="sm" className="sokobanListTable">
                <thead>
                    <tr>
                        <th>Group</th>
                        <th>Puzzle</th>
                        <th>Personal Best</th>
                        <th>Global Best</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(puzzleData).map(([pname, pdata]) =>
                        <SokobanPuzzleListRow key={'puzzle_row_' + pname} data={pdata} />
                    )}
                </tbody>
            </Table>
        </div>
    );
}
