import './Shop.css';

import { Container } from 'react-bootstrap';
import {Routes, Route, useSearchParams} from 'react-router-dom';

import NotFound from '../home/NotFound.js';
import CommonNavbar from '../common/CommonNavbar.js';

import ShopView from './ShopView.js';
import ShopHeader from './ShopHeader.js';

export default function ShopAppRouter() {
    let [searchParams] = useSearchParams();
    // let event = searchParams.get('event_id');
    // let h = searchParams.get('h');

    // <Route path="admin" element={<EventTicketAdmin eventID={event} />} />
    return (
        <div>
            <ShopHeader />

            <div className="AppBody">
            <Container className="AppContainer">

                <Routes>
                    <Route path='*' element={<NotFound />} />

                    <Route
                        index 
                        element={
                            <ShopView />
                        }
                    />
                </Routes>

            </Container>
            </div>
        </div>
    );
}
