import React from 'react';
import { Modal } from 'react-bootstrap';

export default function PayPalDonationButton() {
    const buttonRef = React.useRef(null);
    React.useEffect(() => {
        // Depends on loading the PayPal SDK in index.html
        const button = window.PayPal.Donation.Button({
            env: 'production',
                hosted_button_id: 'M3J563TBVFM4S',
                image: {
                    src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif',
                    alt: 'Donate with PayPal button',
                    title: 'PayPal - The safer, easier way to pay online!',
                },
                onComplete: (params) => {
                    // Your onComplete handler
                    console.log(params);
                    alert('Thank you for your donation! Cocoa Happ!');
                },
        })
        const buttonID = buttonRef.current.id;
        button.render('#' + buttonID);
    }, []);

    return (
        <span ref={buttonRef} id="donate-button" />
    );
}
