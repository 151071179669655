import React, { Component } from "react";
import { Modal } from 'react-bootstrap';

import { FLAG_EMOJI, GOAL_EMOJI } from './SokobanConstants.js';

class SokobanRules extends Component {
    render() {
	let props = this.props;
        return (
            <Modal
                {...props}
                className="sokobanRules"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        How to Play Sokoban
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <b>The objective</b> of the game is to help Cocoa
                        {' '}
    	                    <div className="sokobanCell flag">
                                <div className="sokobanInnerCell">{FLAG_EMOJI}</div>
    	                    </div>
                        {' '}
                        reach the meat
                        {' '}
    	                    <div className="sokobanCell goal">
                                <div className="sokobanInnerCell">{GOAL_EMOJI}</div>
    	                    </div>
                        {' '}
                        by moving Cocoa
                        {' '}
       	                    <div className="sokobanCell flag">
                                <div className="sokobanInnerCell">{FLAG_EMOJI}</div>
     	                    </div>
                        {' '}
                        and boxes
                        {' '}
     	                    <div className="sokobanCell block">
                                <div className="sokobanInnerCell" />
     	                    </div>
                        {' '}
                        horizontally or vertically.
                    </p>
                    <p>
                        When Cocoa or a box moves, they will slide in a direction until they are out of bounds 
                        or until they hit a box (or Cocoa). Once they slide off the grid, they are no
                        longer in play. You lose if Cocoa slides out of bounds.
                    </p>
                    <p>
                        <b>Interact</b> with the puzzle by dragging Cocoa/boxes. You can also use {' '}
                        <b>keyboard direction pad</b> to navigate on the grid and use <b>space</b> to
                        select and deselect Cocoa/boxes. Once selected, the direction
                        pad will slide it in that direction.
                    </p>
                </Modal.Body>
            </Modal>
        );
    }
}

export default SokobanRules;
