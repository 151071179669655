import './Corgillogical.css';

import React from 'react';
import { Carousel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom';

import Loading from '../common/Loading.js';

import CorgillogicalPackItem from './CorgillogicalPackItem.js';


function CorgillogicalAllPuzzles({group, puzzles, onUnlockPuzzle}) {
    const navigate = useNavigate();

    // Construct idx <-> puzzle_id bidirectional maps
    let puzzleIDs = Object.values(puzzles).map(p => p.puzzle_id);
    let puzzleIDsLookup = {};
    for (let idx = 0; idx < puzzleIDs.length; idx++) {
        puzzleIDsLookup[puzzleIDs[idx]] = idx;
    }

    // Grab the puzzle_id url param and set up react to refresh render
    // upon receiving new values.
    let searchParams = useSearchParams()[0];
    let puzzleID = searchParams.get('puzzle_id');
    let puzzleIdxFromURL = puzzleID in puzzleIDsLookup ? puzzleIDsLookup[puzzleID] : 0; 
    const [puzzleIdx, setPuzzleIdx] = React.useState(puzzleIdxFromURL);
    React.useEffect(() => {
        setPuzzleIdx(puzzleIdxFromURL);
    }, [puzzleIdxFromURL]);

    const handleOnSelect = (eventKey, event) => {
        setPuzzleIdx(eventKey);
        let newPuzzleID = puzzleIDs[eventKey];
        navigate('/puzzle/?pack=' + group.group_id + '&puzzle_id=' + newPuzzleID);
    };    

    return (
        <div className="puzzlePackView">
            <div className="puzzlePackHeader">
                <OverlayTrigger placement="bottom" overlay={
                    <Tooltip>{group.desc}</Tooltip>
                }>
                    <h3>{group.name}</h3>
                </OverlayTrigger>
            </div>

            <Carousel 
                activeIndex={puzzleIdx}
                className="puzzleCarousel"
                interval={null}
                keyboard={false}
                variant="dark"
                onSelect={handleOnSelect}
                wrap={false}>
                {
                    Object.values(puzzles).map((p) =>
                        <Carousel.Item>
                            <CorgillogicalPackItem
                                active={puzzleIDs[puzzleIdx] === p.puzzle_id}
                                puzzleData={p}
                                onUnlockPuzzle={onUnlockPuzzle}
                            />
                        </Carousel.Item>
                    )
                }
            </Carousel>
        </div>
    );
}


export default function Corgillogical() {
    let searchParams = useSearchParams()[0];
    let packID = searchParams.get('pack');

    const [levelID, setLevelID] = React.useState(packID);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [groups, setGroups] = React.useState(null);
    const [puzzles, setPuzzles] = React.useState(null);

    const handleLoadPuzzles = React.useCallback(() => {
        setIsLoaded(false);
        fetch('/puzzle/fetch_levels/?level_id=' + levelID)
            .then(res => res.json())
            .then(
                  (payload) => {
                      if ('puzzles' in payload) {
                          setPuzzles(payload['puzzles']);
                          setGroups(payload['group']);
                      }
                      setIsLoaded(true);
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
        );
    }, [levelID]);

    // load once in the beginning
    React.useEffect(() => {
        handleLoadPuzzles();
    }, [handleLoadPuzzles]);

    if (!isLoaded) {
        return <Loading />;
    }
    
    if (!puzzles) {
        return <Navigate replace to="/puzzle/levels/" />;
    }

    return (
        <CorgillogicalAllPuzzles
            group={groups}
            puzzles={puzzles}
            onUnlockPuzzle={handleLoadPuzzles}
        />
    );
}
