import React from "react";
import { Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { QuestionCircle } from 'react-bootstrap-icons';

import CommonNavbar from '../common/CommonNavbar.js';

import SokobanRules from './SokobanRules.js';


export default function SokobanHeader() {
    const [showRules, setShowRules] = React.useState(false);

    return (
        <>
            <CommonNavbar className="sokobanNavbar" title="Sokoban">
                <Nav>
                    <LinkContainer to="/sokoban/">
                        <Nav.Link>Play!</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/sokoban/levels/">
                        <Nav.Link>Levels</Nav.Link>
                    </LinkContainer>
                    <Nav.Link active={false} onClick={() => setShowRules(true)}>
                        <QuestionCircle />
                    </Nav.Link>
                    <NavDropdown align="end" menuVariant="dark">
                        <NavDropdown.Item>
                            <LinkContainer to="/sokoban/account/">
                                <Nav.Link>My Progress</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <LinkContainer to="/sokoban/leaderboard/">
                                <Nav.Link>Leaderboard</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </CommonNavbar>

            <SokobanRules
	        show={showRules}
                onHide={() => setShowRules(false)}
            />
        </>
    );
}
