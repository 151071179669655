import React from 'react';
import { Button, Carousel, Container, Form, InputGroup } from 'react-bootstrap';
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom';

import CorgillogicalPuzzle from '../corgillogical/CorgillogicalPuzzle.js';

import Loading from '../common/Loading.js';
import ResultModal from '../common/ResultModal.js';


function QuizItem({quizData, active}) {
    const [answer, setAnswer] = React.useState('');
    const [prevAnswer, setPrevAnswer] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [note, setNote] = React.useState(null);

    const handleSubmitAnswer = React.useCallback(() => {
        // Don't bother wasting a hit to the server
        if (answer === '' || answer === prevAnswer) {
            return;
        }
        setPrevAnswer(answer);
        setResult(answer.trim() == quizData.answer);

    }, [answer, prevAnswer]);

    const handleKeyDown = React.useCallback((event) => {
        if (!active) {
            return;
        }
        let kc = event.keyCode;
        switch (kc) {
        case 13: // enter
            handleSubmitAnswer();
            break;
        default:
            break;
        }
    }, [handleSubmitAnswer, active]);

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            // unsubscribe event
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    
    return (
        <div className="puzzleView">
            <ResultModal
                result={result}
                onHide={() => setResult(null)}
                winText={note ?? 'Correct!'}
                loseText={note ?? 'Incorrect!'}
            />

            <div className="puzzleContainer">
                <CorgillogicalPuzzle puzzleData={quizData} />
            </div>

            <InputGroup>
                <Form.Control
                    required
                    type="text"
                    placeholder="Answer"
                    aria-label="Puzzle's Answer"
                    value={answer}
                    onChange={e => setAnswer(e.target.value)}
                />
                <Button
                    disabled={answer === prevAnswer}
                    variant="primary"
                    onClick={handleSubmitAnswer}>
                    Submit
                </Button>
            </InputGroup>
        </div>
    );
}


function QuizCarousel({quizID, quizes}) {
    const navigate = useNavigate();

    // Construct idx <-> puzzle_id bidirectional maps
    let puzzleIDs = Object.values(quizes).map(p => p.puzzle_id);
    let puzzleIDsLookup = {};
    for (let idx = 0; idx < puzzleIDs.length; idx++) {
        puzzleIDsLookup[puzzleIDs[idx]] = idx;
    }
    // Grab the puzzle_id url param and set up react to refresh render
    // upon receiving new values.
    let searchParams = useSearchParams()[0];
    let puzzleID = searchParams.get('puzzle_id');
    let puzzleIdxFromURL = puzzleID in puzzleIDsLookup ? puzzleIDsLookup[puzzleID] : 0; 

    const [puzzleIdx, setPuzzleIdx] = React.useState(puzzleIdxFromURL);
    React.useEffect(() => {
        setPuzzleIdx(puzzleIdxFromURL);
    }, [puzzleIdxFromURL]);


    const handleOnSelect = (eventKey, event) => {
        setPuzzleIdx(eventKey);
        let newPuzzleID = puzzleIDs[eventKey];
        navigate('/quiz/?quiz_id=' + quizID + '&problem_id=' + newPuzzleID);
    };    

    let group = Object.values(quizes)[0].group;
    
    return (
        <div className="puzzlePackView">
            <div className="puzzlePackHeader">
                {group.desc}
            </div>

            <Carousel 
                activeIndex={puzzleIdx}
                className="puzzleCarousel"
                interval={null}
                keyboard={false}
                variant="dark"
                onSelect={handleOnSelect}
                wrap={false}>
                {
                    Object.values(quizes).map((p) =>
                        <Carousel.Item>
                            <QuizItem
                                active={puzzleIdx === p.puzzle_id}
                                quizData={p}
                            />
                        </Carousel.Item>
                    )
                }
            </Carousel>
        </div>
    );
}


export default function QuizDetailView({quizID}) {
    const [quizes, setQuizes] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    
    const handleLoadQuizes = React.useCallback(() => {
        setIsLoaded(false);
        fetch('/quiz/fetch/?quiz_id=' + quizID)
            .then(res => res.json())
            .then(
                  (payload) => {
                      setQuizes(payload['quizes']);
                      setIsLoaded(true);
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
        );
    }, [quizID]);

    // load once in the beginning
    React.useEffect(() => {
        handleLoadQuizes();
    }, [handleLoadQuizes]);

    if (!isLoaded) {
        return <Loading />;
    }

    return (
        <QuizCarousel
            quizID={quizID}
            quizes={quizes}
        />
    );
}

