import React from 'react';
import { Alert, Button, Card, Col, Container, Form, Row} from 'react-bootstrap';

import { formatPrice, hashCode } from '../common/Utils.js';
import Loading from '../common/Loading.js';
import PayPalPurchaseButton from '../common/PayPalPurchaseButton.js';
import { postWithToken } from '../common/SecurityUtils.js';


export default function ApparelCartCheckout({cart, onComplete}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [price, setPrice] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);

    let paypalCart = Object.keys(cart).map(
      (sku) => { 
          return {
              sku: sku,
              quantity: cart[sku].quantity,
          };
      }
    );

    let chash = hashCode(JSON.stringify(paypalCart));

    const onPaymentSuccess = React.useCallback((res) => {
        setCompleted(true);
        onComplete();
    });
    
    const onPaymentError = React.useCallback((error) => {
        console.log('fail');
    });
    
    React.useEffect(() => {
        setIsLoaded(false);
        postWithToken('/paypal_api/calculate-price/', {cart: paypalCart})
            .then(res => res.json())
            .then(
                  (payload) => {
                      // Todo split out shipping and all that jazz
                      console.log(payload);
                      setPrice(payload['total']);
                      setIsLoaded(true);
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, [cart, chash]);

    if (!isLoaded) {
        return <Loading />;
    }

    const data = {
    };

    return (
        <Card className="apparelCard">
            <Card.Body>
                <Card.Title>Checkout</Card.Title>
                <Card.Text>
                    <Alert variant="warning">
                        Please note that we are only currently shipping within the United States.
                        Please make sure your shipping address is correct when finalizing payment via
                        Paypal.
                        <br />
                        <br />
                        We print our apparel on demand, so please allow for 1-2 weeks for production and shipment.
                    </Alert>
                </Card.Text>
                <Card.Text className="subtotalRow">
                    Subtotal: {formatPrice(price)}      
                </Card.Text>
                {!completed ?
                    <PayPalPurchaseButton
                        successCallback={onPaymentSuccess}
                        errorCallback={onPaymentError}
                        cart={paypalCart}
                        rData={data}
                    />
                    : 
                    <Card.Text>
                        <b>Thank you for your order!</b>
                    </Card.Text>
                }
            </Card.Body>
        </Card>
    );

}