import React from 'react';
import { Alert, Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';

import Loading from '../common/Loading.js';
import { postWithToken } from '../common/SecurityUtils.js';

import EventDetail from './EventDetail.js'
import EventTicketLinkQR from './EventTicketLinkQR.js';

export default function EventTicketView({eventID, ticketH}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [ticketData, setTicketData] = React.useState(null);
    const [canAdmin, setCanAdmin] = React.useState(false);
    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        setIsLoaded(false);
        fetch('/event/fetch_ticket/?h=' + ticketH)
            .then(res => res.json())
            .then(
                  (payload) => {
                      setTicketData(payload.ticket)
                      setCanAdmin(payload.canAdmin)
                      setIsLoaded(true);                      
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, []);

    const handleAdmit = React.useCallback((e) => {
        setIsLoaded(false);
        const data = {
            'event': ticketData.event_id,
            'ticket': ticketData.id,
        };

        postWithToken('/event/admit_ticket/', data)
            .then(res => res.json())
            .then(
                (payload) => {
                    setTicketData(payload.ticket)
                    setCanAdmin(payload.canAdmin)
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                },
            );        
        }, [ticketData]);
    

    if (!isLoaded) {
        return <Loading />;
    }

    if (!ticketData) {
        return (
            <div>
                This isn{"'"}t a valid ticket
            </div>
        );
    }

    const renderStatus = (tix) => {
        if (tix.used) {
            return <Alert variant="danger">This ticket has been used!</Alert>;
        }

        if (tix.confirmed) {
            return <Alert variant="success">This ticket has been confirmed!</Alert>;
        }

        return <Alert variant="dark">This ticket has not been confirmed yet.</Alert>;
    };

    return (
        <Container>
            {renderStatus(ticketData)}

            <Card style={{marginBottom:'8px', marginTop:'8px'}}>
                <Card.Body>                    
                    <Row>
                        <Col md={4}>
                            <EventTicketLinkQR
                                link={ticketData.link}
                                uuid={ticketData.uuid}
                            />
                        </Col>
                        <Col md={8}>
                            Ticket for <b>{ticketData.event_name}</b>
                            <br />
                            Ticket Reserved By {ticketData.reserver}
                            {ticketData.complimentary && <div><Badge bg="warning">Complimentary Ticket</Badge></div>}
                            {canAdmin && ticketData.confirmed && <div style={{marginTop: '12px'}}><Button disabled={ticketData.used} variant="primary" onClick={handleAdmit}>Admit One</Button></div>}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <EventDetail eventID={ticketData.event_id} />
        </Container>
    );
}
