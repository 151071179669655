import React from 'react';
import { Alert, Button, Card, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Loading from '../common/Loading.js';
import { postWithToken } from '../common/SecurityUtils.js';
import { classes } from '../common/Utils.js';

import EventDetailEditor from './EventDetailEditor.js';

function EventViewStatsTable({data}) {
    return (
        <Table striped hover size="sm" className="sokobanListTable">
            <thead>
                <tr>
                    <th>Tracker</th>
                    <th>Visits</th>
                </tr>
            </thead>
            <tbody>
                {data.map((d) => 
                    <tr>
                        <td>{d.tracker}</td>
                        <td>{d.visits}</td>
                    </tr>
                )}
            </tbody>
        </Table>        
    );
}

export default function EventTicketAdmin({eventID}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [ticketList, setTicketList] = React.useState([]);
    const [eventData, setEventData] = React.useState({});
    const [confirmCount, setConfirmCount] = React.useState(0);
    const [admitCount, setAdmitCount] = React.useState(0);
    const [showPending, setShowPending] = React.useState(false);
    const [showTixLink, setShowTixLink] = React.useState(false);
    const [blurEmail, setBlurEmail] = React.useState(true);
    const [canAction, setCanAction] = React.useState(false);
    const [viewData, setViewData] = React.useState({});
    
    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        setIsLoaded(false);
        fetch('/event/fetch_tickets/?event_id=' + eventID)
            .then(res => res.json())
            .then(
                (payload) => {
                      setErrorMessage(payload.error);
                      setEventData(payload.event);
                      setConfirmCount(payload.confirm_count);
                      setAdmitCount(payload.admit_count);
                      setTicketList(payload.tickets)
		      setCanAction(payload.can_action);
                      setViewData(payload.view_stats);
                      setIsLoaded(true);
                  },
                  (error) => {
                      setErrorMessage(error.error);
                      setIsLoaded(true);
                  }
            )
    }, []);

    const handlePaid = React.useCallback((tid, conf) => {
        setIsLoaded(false);

        const data = {
            'event': eventID,
            'ticket': tid,
            'confirmed': conf,
        };

        postWithToken('/event/edit_ticket/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setConfirmCount(res.confirm_count);
                    setTicketList(res.tickets)
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                },
            );        
        }, []);
    
    if (!isLoaded) {
        return <Loading />;
    }

    const renderTableHeader = () => {
        // This is the full leaderboard across all puzzles
        return (
            <thead>
                <tr>
                    <th>Ticket ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Date</th>
                    <th>Confirmed</th>
                    <th>Used</th>
                    <th>Complimentary</th>
                    <th>Mark As</th>
                    {showTixLink ? <th>Ticket Link</th> : null}
                </tr>
            </thead>
        );
    };

    const renderTableBody = () => {
        const emailClass = classes({
            emailField: true,
            blur: blurEmail,
            });
        return (
            <tbody>
                {ticketList.map((t) => (t.confirmed || showPending) &&
                                <tr>
                                <td>{t.id}</td>
                                <td>{t.reserver}</td>
                                <td><span className={emailClass}>{t.email}</span></td>
                                <td>{t.create_time}</td>
                                <td>{t.confirmed ? 'Yes' : 'No'}</td>
                                <td>{t.used ? 'Yes' : 'No'}</td>
                                <td>{t.complimentary ? 'Yes' : 'No'}</td>
                                <td>    
                                    <Button
                                        variant="primary"
					disabled={!canAction}
                                        onClick={() => handlePaid(t.id, !t.confirmed)}>
                                        {t.confirmed ? 'Unpaid' : 'paid'}
                                    </Button>
                                </td>
                                {showTixLink ? <td>{t.link}</td> : ''}
                                </tr>
                )}
            </tbody>
        );
    };

    if (errorMessage) {
        return <Alert variant="danger">{errorMessage}</Alert>;
    }
    
    const renderEventEditor = () => {
        // Only render event data if have edit permission.
        if (!canAction) {
            return null;
        }
        return (
            <EventDetailEditor
                eventID={eventID}
                eventData={eventData}
                style={{marginBottom: '8px'}}
            />
        );
    };
    
    return (
        <div>
            <Card style={{marginBottom: '8px'}}>
                <Card.Header>{eventData.name}</Card.Header>
                <Card.Body>
                    Currently there are {confirmCount} confirmed tickets
                    and {ticketList.length - confirmCount} unconfirmed tickets.
                    <br /> <br />
                    Currently there are {admitCount} tickets admitted.
		            <br /> <br />
		            <a href={'/event/?event_id=' + eventID}>
		                Click here to go back to the public event page
		            </a>
                    <br />
		            <a href={'/event/poster/?event_id=' + eventID}>
		                Click here to go to the poster tool
		            </a>
                </Card.Body>
            </Card>

            {renderEventEditor()}
            
            <Card style={{marginBottom: '8px'}}>
                <Card.Header>Event View Stats</Card.Header>
                <Card.Body>
                    <EventViewStatsTable data={viewData} />
                </Card.Body>
            </Card>
            
            <Alert>
              Marking the ticket as paid will send a confirmation email to the ticket reserver.
              If someone requests for another email to be sent, just mark as unpaid first and re-mark as paid.
            </Alert>
            <Card>
                <Card.Header>Ticket Reservations</Card.Header>
                <Card.Body>
                    <div>
                        <Form.Check 
                            inline
                            type="switch"
                            label="Show Ticket Links"
                            onChange={(e) => setShowTixLink(e.target.checked)}
                        />
                        <Form.Check 
                            inline
                            type="switch"
                            label="Show Pending Tickets"
                            onChange={(e) => setShowPending(e.target.checked)}
                        />
                        <Form.Check 
                            inline
                            type="switch"
                            label="Show Email"
                            onChange={(e) => setBlurEmail(!e.target.checked)}
                        />
                    </div>
                    <Table striped hover size="sm" className="sokobanListTable">
                        {renderTableHeader()}
                        {renderTableBody()}
                    </Table>
                </Card.Body>
            </Card>
        </div>
    );
}
