import React from 'react';

import { Button } from 'react-bootstrap';

import QRCode from 'react-qr-code';

export default function EventTicketLinkQR({link, uuid = null}) {
    const [showShort, setShowShort] = React.useState(uuid ? true : false);

    const qrData = showShort ? uuid : link;    
    return (
        <div className="eventTicketQR">
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qrData}
                viewBox={`0 0 256 256`}
            />
            {uuid &&
             <Button
                 className="hiddenButton"
                 onClick={() => setShowShort(!showShort)}>
                 Toggle QR
             </Button>
            }
        </div>
    );
}
