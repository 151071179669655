import React from 'react';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { classes } from '../common/Utils.js';


export default function SizeBox({activeSize, size, onEnter, onLeave, onSelect}) {
    const boxClass = classes({
        sizeBox: true,
        selected: activeSize == size,
    });

    return (
            <div
                className={boxClass}
                onClick={() => onSelect(size)}
                onMouseEnter={() => onEnter(size)}
                onMouseLeave={onLeave}>
                {size}
            </div>
    );
}
