import './Home.css';


export default function NotFound() {
    return (
        <div className="homeContainer">
            <div className="notFoundText">
                Awoo~ am unable to find
            </div>

            <div className="notFound404 firstLetter">4</div>
            <div className="notFound404 secondLetter">0</div>
            <div className="notFound404 thirdLetter">4</div>

            <img 
                className="notFound404Image corgiImage"
                src="/static/corgillogical.jpeg"
                alt="Cute Corgi"
            />
        </div>
    );
}
