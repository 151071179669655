import React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import Loading from '../common/Loading.js';

import ShopApparelView from './ShopApparelView';
import ShopEventView from './ShopEventView';

export default function ShopView() {
    let [searchParams, setSearchParams] = useSearchParams();
    let tabParam = searchParams.get('tab') ?? 'apparel';

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [products, setProducts] = React.useState(null);
    const [activeTab, setActiveTab] = React.useState(tabParam);

    if (tabParam !== activeTab) {
        setActiveTab(tabParam);
    }

    let handleTabChange = (tab) => {
        setSearchParams({tab: tab});
        setActiveTab(tab);
    };

    React.useEffect(() => {
        fetch('/shop/fetch_sku/')
            .then(res => res.json())
            .then(
                  (payload) => {
                      setProducts(payload);
                      setIsLoaded(true);
                  },
                  (error) => {
                      console.log('died');
                      setIsLoaded(true);
                  }
            )
    }, []);


    if (!isLoaded) {
        return <Loading />;
    }

    return (
        <Container>
            <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onSelect={handleTabChange}>
                <Tab eventKey="apparel" title="Apparel">
                    <ShopApparelView products={products.AP} />
                </Tab>
                <Tab eventKey="events" title="Events"> 
                    <ShopEventView events={products.TX} />
                </Tab>
            </Tabs>
        </Container>
    );
}
