import './App.css';

import {Routes, Route } from 'react-router-dom';

import CorgillogicalAppRouter from './corgillogical/CorgillogicalAppRouter.js';
import HomeAppRouter from './home/HomeAppRouter.js';
import SokobanAppRouter from './sokoban/SokobanAppRouter.js';
import ShopAppRouter from './shop/ShopAppRouter.js';
import EventAppRouter from './event/EventAppRouter.js';
import QuizAppRouter from './quiz/QuizAppRouter.js';

export default function App() {
    // This is a url => component mapping. The top level /sokoban route specifies the
    // template being used (Layout component defined above) and the children path
    // specify the detailed component whose content will replace the <Outlet /> element
    // in <Layout />
    return (
        <div className="App">
            <Routes>
                <Route path="/sokoban/*" element={<SokobanAppRouter />} />
                <Route path="/puzzle/*" element={<CorgillogicalAppRouter />} />
                <Route path="/shop/*" element={<ShopAppRouter />} />
                <Route path="/event/*" element={<EventAppRouter />} />
                <Route path="/quiz/*" element={<QuizAppRouter />} />
                <Route path="/*" element={<HomeAppRouter />} />
            </Routes>
        </div>
    );
}
