import React from 'react';
import { Modal, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { QuestionCircle } from 'react-bootstrap-icons';

import CommonNavbar from '../common/CommonNavbar.js';
import SiteURL from '../common/SiteURL.js';


export default function CorgillogicalHeader() {
    const [showRules, setShowRules] = React.useState(false);

    let tutorialURL = new SiteURL()
        .corgillogical()
        .setParams({pack: 'tutorial'})
        .getRelative();

    return (
        <>
            <CommonNavbar title="Corgillogical">
                <Nav>
                    <LinkContainer to="/puzzle/levels/">
                        <Nav.Link>Puzzles</Nav.Link>
                    </LinkContainer>
                    <Nav.Link active={false} onClick={() => setShowRules(true)}>
                        <QuestionCircle />
                    </Nav.Link>
                    <NavDropdown align="end" menuVariant="dark">
                        <NavDropdown.Item>
                            <LinkContainer to="/puzzle/account/">
                                <Nav.Link>My Progress</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <LinkContainer to="/puzzle/leaderboard/">
                                <Nav.Link>Leaderboard</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>

                <Modal centered show={showRules} onHide={() => setShowRules(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            How to Puzzle
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Each Corgillogical Puzzle is a problem with a word or a short phrase as its answer.
                            The answers are usually tucked away without clear instructions on how to get them, and requires intuitive leaps to get them. 
                        </p>
                        <p>
                            The objective is to find patterns and hidden clues in the puzzle to work your way to the final answer.
                            Use the text box beneath the puzzle to submit your answer. 
                            Sometimes the system will give you feedback on your answer if you are on the right track.
                        </p>
                        <p>
                            Some puzzles also have hints if the lightbulb symbol on the top right lights up.
                        </p>
                        <p>
                            These puzzles range from mini puzzles that you may find in escape rooms,
                            all the way to puzzles found in puzzle hunts (e.g. MIT Mystery Hunt). If you are new to puzzles, 
                            try out some <a href={tutorialURL}>tutorial puzzles here!</a>
                        </p>
                    </Modal.Body>
                </Modal>
                
            </CommonNavbar>
        </>
    );
}
