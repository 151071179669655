import React from 'react';
import { Alert, Button, Card, Form, Row, Col, ListGroup, Container } from 'react-bootstrap';

import Loading from '../common/Loading.js';
import PayPalPurchaseButton from '../common/PayPalPurchaseButton.js';
import { postWithToken } from '../common/SecurityUtils.js';

import EventTicketLinkQR from './EventTicketLinkQR.js';

import { formatPrice } from '../common/Utils.js';
import { fetchLoginStatus } from '../common/SecurityUtils.js';


export default function EventTicketSingingReservationForm({eventData, eventID, canReserve}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [validated, setValidated] = React.useState(false);

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [understood, setUnderstood] = React.useState(!noSKU);
    const [numTickets, setNumTickets] = React.useState(1);
    const [totalPrice, setTotalPrice] = React.useState(unitPrice);
    
    const [ticketLinks, setTicketLinks] = React.useState([]);
    const [message, setMessage] = React.useState(null);

    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        fetchLoginStatus((loggedIn, name) => {
            setIsLoaded(true);
            setIsLoggedIn(loggedIn);
            // setName(name);
        });
    }, []);

    const noSKU = !eventData.ticket_sku;
    const unitPrice = noSKU ? null : eventData.ticket_sku.price;



    const handleSubmit = React.useCallback((e) => {
        const form = e.currentTarget;
        let isValid = form.checkValidity();
        if (isValid === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);

        if (!isValid) {
            return;
        }

        setIsLoaded(false);
        const data = {
            'event': eventID,
            'name': name,
            'email': email,
            'num': numTickets,
        };

        postWithToken('/event/reserve_ticket/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
                    setTicketLinks(res.tickets);
                    setIsLoaded(true);
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
            );        
        }, [name, email, numTickets]);

    const onPaymentSuccess = React.useCallback((res) => {
        setMessage(res.msg);
        setTicketLinks(res.tickets);
    });
    
    const onPaymentError = React.useCallback((error) => {
        setMessage(error.msg);
    });

    const renderTicketLink = (link) => {
        return (
            <Container style={{textAlign:'left'}}>
                <Row>
                    <Col sm={4}>
                        <EventTicketLinkQR link={link} />
                    </Col>
                    <Col sm={8}><b>Ticket Link</b><br />{link}</Col>
                </Row>
            </Container>
        );
    };

    const renderActionButtons = (disabled) => {
        return (
            <Button
                disabled={disabled}
                variant="primary"
                onClick={(e) => handleSubmit(e)}>
                Reserve Ticket(s)
            </Button>
        );
    };

    const renderPrice = () => {
        return (
            <Form.Group className="subtotalRow">
                Total: {formatPrice(totalPrice)}
            </Form.Group>
        );
    };

    if (ticketLinks && ticketLinks.length > 0) {
        if (!noSKU) {
            return (
                <Card>
                    <Card.Header>Successfully Purchased Tickets </Card.Header>
                    <Card.Body>
                        <b>Thank you for your support!</b>
                        <br />
                        Please check your email for links to your digital tickets -
                        be sure to check your spam folder, just in case.
                        <br /> <br />
                        The link to your digital ticket(s) are also shown below:

                        <ListGroup>
                            {ticketLinks.map(tl => <ListGroup.Item key={'ticket_id_'+tl.id}>{renderTicketLink(tl)}</ListGroup.Item>)}
                        </ListGroup>
                    </Card.Body>
                </Card>
            );
        }
        return (
            <Card>
                <Card.Header> Successfully Reserved Tickets </Card.Header>
                <Card.Body>
                    <h2 style={{color: 'red'}}>Please keep a copy of the ticket link(s) below.</h2> Remember that your tickets are
                    not valid until you reached out to the event coordinator to confirm payment!

                    <ListGroup>
                        {ticketLinks.map(tl => <ListGroup.Item key={'ticket_id_'+tl.id}>{renderTicketLink(tl)}</ListGroup.Item>)}
                    </ListGroup>
                </Card.Body>
            </Card>
        );
    }

    if (!canReserve) {
        return (
            <Card>
                <Card.Header> Reserve Tickets </Card.Header>
                <Card.Body>
                    Sorry, ticket reservation has been closed!
                </Card.Body>
            </Card>
        );
    }

    const renderBody = () => {
        if (!isLoaded) {
            return <Loading />;
        }

        return (
            <>
                {message && <Alert variant="danger">{message}</Alert>}

        <Form noValidate validated={validated}>
            <Form.Group as={Row} className="mb-3" controlId="formName">
                <Form.Label column>Full Name</Form.Label>
                <Col>
                    <Form.Control
                        required
                        value={name}
                        type="text"
                        placeholder="Your Name"
                        onChange={e => setName(e.target.value)}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formEmail">
                <Form.Label column>Email address</Form.Label>
                <Col>
                    <Form.Control
                        required
                        value={email}
                        type="email"
                        placeholder="Enter email"
                        onChange={e => setEmail(e.target.value)}
                    />
                </Col>
            </Form.Group>            

            <Form.Group as={Row} className="mb-3" controlId="formNumTickets">
                <Form.Label column>Number of Ticket(s)</Form.Label>
                <Col>
                    <Form.Select
                        aria-label="Default select example"
                        onChange={e => {
                            setNumTickets(e.target.value);
                            setTotalPrice(e.target.value * unitPrice);
                        }}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </Form.Select>
                </Col>
            </Form.Group>

            {noSKU && 

            <Form.Group as={Row} className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                    required
                    type="checkbox"
                    label="I understand that tickets are not valid until I reach out to the event coordinator to confirm payment, and 
                    reservation may be voided if we cannot confirm your payment."
                    onChange={e => setUnderstood(e.target.checked)} />
            </Form.Group>

            }

            {noSKU || renderPrice()}

            {renderActionButtons(!understood || !isLoaded)}
        </Form>


            </>
        );
    };

    return (
        <Card>
            <Card.Header> Reserve Tickets </Card.Header>
            <Card.Body>{renderBody()}</Card.Body>
        </Card>
    );
}
