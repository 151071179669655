import SiteURL from '../common/SiteURL.js';

import * as GridConst from './SokobanConstants.js';

export class SokobanLevel {
    parSteps = 0;
    desc = 'Stage description';
    name = 'AbstractGrid';
    group = 'others';
    /**
     * These are static information about the level that we opted to
     * dynamically compute and cache upfront via init()
     */
    width = null;
    height = null;
    goal = [null, null];
    flag = [null, null];
    personalBest = null;

    constructor(name, desc, group, grid, bestSol) {
        this.initialized = false;
        this.name = name;
        this.desc = desc;
        this.group = group;
        this.grid = grid;
        this.parSteps = bestSol;
    }
    static fromPayload(payload) {
        let p = payload;
        if ('puzzle' in p) {
            p = p.puzzle;
        }
        let l = new SokobanLevel(
            p.name, 
            p.desc,
            p.group,
            p.grid,
            p.bestSolution,
        );
        l.expired = p.invisible ?? null;
        l.expiry = p.expiry ?? null;

        l.personalBest = payload.solved ? payload.steps : null;
        return l;
    }
    update(payload) {
        // Current assumption is that this is just the best score
        this.parSteps = payload;
        return this;
    }
    getGroup() {
        return this.group;
    }
    getDesc() {
	return this.desc;
    }
    par() {
	// Best solution known (# steps)
	return this.parSteps;
    }
    getName() {
	return this.name;
    }
    getWidth() {
        return this.width;
    }
    getHeight() {
        return this.height;
    }
    isGoal(i, j) {
        return this.get()[i][j] === GridConst.G ||
            this.get()[i][j] === GridConst.BG;
    }
    /**
     * Useful for getting a representation to replicate on the server side.
     */
    serialize() {
        // flattens the grid into a string
        let grid = this.get().map(l => l.join('')).join('');
        return {
            name_id: this.getName(),
            name: this.getDesc(),
            group: this.getGroup(),
            description: this.getDesc(),
            width: this.getWidth(),
            height: this.getHeight(),
            grid: grid,
        };
    }
    /**
     * Preprocess the grid to extract and cache static information.
     */
    init() {
        // Don't reinitialize
        if (this.initialized) {
            return this;
        }

	let grid = this.get();
	this.height = grid.length;
	this.width = grid[0].length;
	for (let i = 0; i < this.height; i++) {
	    for (let j = 0; j < this.width; j++) {
		if (grid[i][j] === GridConst.G) {
                    this.goal = [i, j];
                } else if (grid[i][j] === GridConst.BG) {
                    this.goal = [i, j];
		} else if (grid[i][j] === GridConst.F) {
                    this.flag = [i, j];
		}
	    }
	}
        this.initialized = true;
        return this;
    }
    /**
     * This function supposedly return the same result as get() in 
     * 99% of the cases. This is to support some stages that want to
     * cover up the goal with block initially
     */
    getPlayGrid() {
        return this.get().map((row) => row.map(b => b === GridConst.BG ? GridConst.B : b));
    }
    get() {
	// See below for sample grid. The grid must have exactly
	// one 2 and one 3. The below is an empty (and invalid) grid
	// for the purpose of ease of creating new grids.

        // Returns a deep copy of a 2d array
        return this.grid.map(row => row.slice());
    }
    getUnicodeRepresentation() {
        return this.get().map((row) => row.map(GridConst.toUnicode).join('')).join('\n');
    }

    getURL() {
        // always return production url for now
        return new SiteURL().sokoban().setParams({level:this.name}).getAbsolute();
    }
}

export class LH001 extends SokobanLevel {
    parSteps = 7;
    desc = 'Hard Level 1';
    name = 'LH001';
    group = 'hard';
    get() {
	return [
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 1 ],
		[ 0 , 2 , 0 , 0 , 0 , 1 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 1 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 3 , 0 , 0 , 1 ],
		[ 0 , 0 , 0 , 0 , 0 , 1 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 1 , 0 , 1 , 0 , 0 , 1 , 0 , 0 ],
		];
    }
}

export class LM001 extends SokobanLevel {
    parSteps = 4;
    desc = 'Medium Level 1';
    name = 'LM001';
    group = 'medium';
    get() {
	return [
		[ 0 , 1 , 0 , 0 , 0 , 0 , 0 , 1 ],
		[ 0 , 0 , 0 , 0 , 1 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 1 , 1 , 0 , 0 , 1 ],
		[ 1 , 0 , 0 , 0 , 3 , 0 , 0 , 1 ],
		[ 0 , 0 , 0 , 2 , 0 , 0 , 0 , 0 ],
		[ 0 , 1 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 1 , 0 , 1 , 0 , 0 ],
		];
    }
}

export class LM002 extends SokobanLevel {
    parSteps = 5;
    desc = 'Medium Level 2';
    name = 'LM002';
    group = 'medium';
    get() {
	return [
		[ 0 , 1 , 0 , 0 , 0 , 1 , 0 , 0 ],
		[ 1 , 0 , 0 , 1 , 0 , 0 , 0 , 1 ],
		[ 0 , 0 , 0 , 0 , 0 , 1 , 0 , 0 ],
		[ 0 , 0 , 2 , 3 , 0 , 0 , 0 , 0 ],
		[ 0 , 1 , 0 , 0 , 0 , 0 , 0 , 1 ],
		[ 1 , 0 , 0 , 1 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 1 , 0 , 1 , 1 , 0 , 0 , 1 ],
		];
    }
}


export class LH002 extends SokobanLevel {
    parSteps = 9;
    desc = 'Hard Level 2';
    name = 'LH002';
    group = 'hard';
    get() {
	return [
                [ 0 , 1 , 0 , 0 , 0 , 0 , 1 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 1 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 2 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 3 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 1 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 1 , 0 , 1 , 0 , 0 , 0 , 0 , 1 ],
		];
    }
}


export class LH003 extends SokobanLevel {
    parSteps = 9;
    desc = 'Hard Level 3';
    name = 'LH003';
    group = 'hard';
    get() {
	return [
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 1 ],
		[ 1 , 0 , 1 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 1 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 3 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 2 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 1 ],
		[ 1 , 0 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 1 , 0 , 0 , 0 , 1 , 0 ],
		];
    }
}

export class LH004 extends SokobanLevel {
    parSteps = 15;
    desc = 'Spiraling In';
    name = 'LH004';
    group = 'hard';
    get() {
	return [
		[ 0 , 1 , 0 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 0 , 1 , 0 ],
		[ 0 , 0 , 0 , 1 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 0 , 0 , 1 ],
		[ 0 , 0 , 0 , 0 , 3 , 0 , 0 , 0 ],
		[ 0 , 0 , 1 , 0 , 0 , 0 , 0 , 0 ],
		[ 0 , 0 , 0 , 0 , 0 , 1 , 0 , 1 ],
		[ 1 , 0 , 0 , 0 , 0 , 0 , 0 , 2 ],
		];
    }
}

export class LE001 extends SokobanLevel {
    parSteps = 3;
    desc = 'Easy Level 1';
    name = 'LE001';
    group = 'easy';
    get() {
	return [
		[ 2 , 0 , 1 ],
		[ 0 , 3 , 0 ],
		[ 1 , 0 , 1,],
		];
    }
}

export class LE002 extends SokobanLevel {
    parSteps = 3;
    desc = 'Easy Level 2';
    name = 'LE002';
    group = 'easy';
    get() {
	return [
		[ 0 , 2 , 0 , 1 ],
		[ 1 , 1 , 0 , 0 ],
		[ 0 , 3 , 0 , 0 ],
                [ 0 , 0 , 0 , 0 ],
                [ 1 , 0 , 1 , 0 ],
		];
    }
}

export class LE003 extends SokobanLevel {
    parSteps = 3;
    desc = 'Think Inside The Box';
    name = 'LE003';
    group = 'easy';
    get() {
	return [
		[ 0 , 0 , 2 , 0 , 0 ],
		[ 0 , 1 , 1 , 1 , 0 ],
		[ 0 , 1 , 3 , 1 , 0 ],
                [ 0 , 1 , 1 , 1 , 0 ],
                [ 0 , 0 , 0 , 0 , 0 ],
		];
    }
}

export class LE004 extends SokobanLevel {
    parSteps = 2;
    desc = 'So Close Yet So Far';
    name = 'LE004';
    group = 'easy';
    get() {
	return [
		[ 1 , 2 , 1 , 1 , 1 ],
		[ 1 , 3 , 0 , 0 , 1 ],
		[ 1 , 0 , 0 , 0 , 1 ],
                [ 1 , 0 , 0 , 0 , 1 ],
                [ 1 , 1 , 1 , 1 , 1 ],
		];
    }
}

export class SokobanLevels {
    static groupDesc = {
        daily: 'Daily Puzzles',
        easy: 'Easy Levels',        
        medium: 'Medium Levels',
        hard: 'Hard Levels',
        other: 'Uncategorized Levels',
    };

    static loaded = false;
    static allLevels = {};

    static installOfflineLevels() {
        if (this.loaded) {
            return;
        }
        this.allLevels = this.process([
            new LE001(),
            new LE002(),
            new LE003(),
            new LE004(),
            new LM001(),
            new LM002(),
            new LH001(),
            new LH002(),
            new LH003(),
            new LH004(),
        ]);

        this.loaded = true;
    }

    static getDesc(desc) {
        if (desc in this.groupDesc) {
            return this.groupDesc[desc];
        }
        return desc;
    }

    static get(level) {
        if (level !== null && level in this.allLevels) {
            return this.allLevels[level];
        }
        return null;
    }
    static uninstall() {
        this.loaded = false;
        this.allLevels = {};        
    }
    static install(rawLevels) {
        if (this.loaded) {
            return;
        }
        let levels = [];
        for (let levelName in rawLevels) {
            let level = SokobanLevel.fromPayload(rawLevels[levelName])
            levels.push(level);
        }
        
        this.allLevels = this.process(levels);
        this.loaded = true;
    }

    static getInitialLevel() {
        if (this.loaded) {
            // attempt to grab the most recent daily puzzle
            let allLevels = this.getAllLevels();
            for (let levelName in allLevels) {
                let level = allLevels[levelName];
                if (level.getGroup() === 'daily') {
                    return level.getName();
                }
            }
        }
        return 'LE001';
    }

    static process(levels) {
        let levelMap = {};
        for (let level of levels) {
            // at some point doing this upfront might not scale
            level.init();
            levelMap[level.getName()] = level;
        }
        return levelMap;
    }
    static getAllLevels() {
        return this.allLevels;
    }
}
