import './Corgillogical.css';

import React from "react";
import { Button, Card } from 'react-bootstrap';
import { FilePersonFill } from 'react-bootstrap-icons';

import { PackViewFooter } from '../common/Footer.js';
import Loading from '../common/Loading.js';
import { classes } from '../common/Utils.js';

function CorgillogicalLevelCard({group}) {
    const needsLogIn = group.extra.login != null;

    const cls = classes({
        'loginRequiredBody': needsLogIn,
    });

    return (
        <Card className="puzzlePack">
            <Card.Header as="h5">{group.name}</Card.Header>
            <Card.Body className={cls}>
                <Card.Text>{group.desc}</Card.Text>
                <Button
                    href={'/puzzle/?pack=' + group.group_id}
                    variant="primary">
                    {group.extra.button ?? 'View Puzzles'}
                </Button>
            </Card.Body>
            {
                needsLogIn &&
                <Card.Footer className="loginRequiredFooter">
                    <Card.Text>
                        <FilePersonFill size={24} />
                        {group.extra.login}
                    </Card.Text>
                </Card.Footer>
            }
        </Card>
    );
}

function CorgillogicalAllLevels({groups}) {
    let entries = Object.entries(groups);
    if (entries.length === 0) {
        return (
            <div>
                There are no puzzle packs available at this moment, sorry!
            </div>
        );
    }
    return (
        <div>
            {
                entries.map(
                    ([groupID, group]) =>
                        <CorgillogicalLevelCard
                            key={'group_' + groupID}
                            group={group}
                        />
                )
            }
        </div>
    );
}

export default function CorgillogicalLevels({pack=null}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [groups, setGroups] = React.useState(null);

    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        setIsLoaded(false);
        fetch('/puzzle/fetch_levels/')
            .then(res => res.json())
            .then(
                  (payload) => {
                      setGroups(payload['groups']);
                      setIsLoaded(true);
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, []);

    if (!isLoaded) {
        return <Loading />;
    }
    
    return (
        <div>
            <CorgillogicalAllLevels groups={groups} />
            <PackViewFooter />
        </div>
    );
}
