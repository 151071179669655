import './Sokoban.css';

import { Container } from 'react-bootstrap';
import {Routes, Route, useSearchParams} from 'react-router-dom';

import MyAccountView from '../common/MyAccountView.js';
import NotFound from '../home/NotFound.js';

import Sokoban from './Sokoban.js';
import SokobanAccountView from './SokobanAccountView.js';
import SokobanEditorView from './SokobanEditorView.js';
import SokobanHeader from './SokobanHeader.js';
import SokobanLeaderboardView from './SokobanLeaderboardView.js';
import SokobanLevelView from './SokobanLevelView.js';
import SokobanPlayerView from './SokobanPlayerView.js';
import SokobanBattle from './SokobanBattle.js';

export default function SokobanAppRouter() {
    let [searchParams] = useSearchParams();
    let level = searchParams.get('level');
    let playerID = searchParams.get('player_id');

    return (
        <div>
            <SokobanHeader />

            <div className="AppBody">
            <Container className="AppContainer">

                <Routes>
                    <Route path='*' element={<NotFound />} />

                    <Route
                        index 
                        element={
                            <Sokoban 
                                key={'sokoban_' + level}
                                level={level}
                            />
                        }
                    />
                    <Route path="levels" element={<SokobanLevelView />} />
                    <Route path="editor" element={<SokobanEditorView />} />
                    <Route path="leaderboard" element={<SokobanLeaderboardView />} />
                    <Route path="account" element={
                        <MyAccountView next="/sokoban/account/">
                            <SokobanAccountView />
                        </MyAccountView>
                    } />
                    <Route path="player" element={<SokobanPlayerView playerID={playerID} />} />
                    <Route path="battle" element={<SokobanBattle playerID={playerID} />} />
                </Routes>

            </Container>
            </div>
        </div>
    );
}
