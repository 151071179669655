import React from "react";

import { Button, ListGroup, Modal } from 'react-bootstrap';
import { Lightbulb, LockFill, UnlockFill } from 'react-bootstrap-icons';

import { postWithToken } from '../common/SecurityUtils.js';
import Loading from '../common/Loading.js';


function HintItem({puzzleID, hintID, hint}) {
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const [needsConfirmation, setNeedsConfirmation] = React.useState(false);
    const [hintData, setHintData] = React.useState(hint);
    // Initially loaded since we'd only fetch data upon user interaction
    const [isLoaded, setIsLoaded] = React.useState(true);

    const unlocked = hintData.unlocked;
    const handleOnClick = () => {
        // If the puzzle was already unlocked, simply toggle the body of the hint.
        if (unlocked) {
            setIsCollapsed(!isCollapsed);
            return;
        }
        
        // Otherwise, we will ask the server to unlock the hint with confirmation
        setNeedsConfirmation(!needsConfirmation);
    };

    const handleUnlock = () => {
        // Expand the hint, hide the unlock confirmation button, and show
        // the loading indicator and wait for the server response.
        setNeedsConfirmation(false);
        setIsCollapsed(false);
        setIsLoaded(false);

        let data = {
            puzzle_id: puzzleID,
            hint_id: hintID,
        };

        postWithToken('/puzzle/hint/', data)
            .then(res => res.json())
            .then(
                (payload) => {
                    setHintData(payload);
                    
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                },
            );        
    };


    const renderHintBody = () => {
        if (!isLoaded) {
            return <Loading />;
        }

        return <p>{hintData.body}</p>;
    };

    const itemKey = [puzzleID, hintID, unlocked ? 'unlocked' : 'locked'].join('::');
    return (
        <ListGroup.Item className="d-flex justify-content-between align-items-start" key={itemKey}>
            <span>
                <a href="#" onClick={handleOnClick}>
                    {unlocked ? <UnlockFill /> : <LockFill />}
                    {' '}
                    <b>{hintData.desc}</b>
                </a>
                {isCollapsed || renderHintBody()}
            </span>
            <span>
                {needsConfirmation &&
                    <Button 
                        size="sm"
                        variant="success"
                        onClick={handleUnlock}>
                        Unlock Hint
                    </Button>
                }
            </span>
        </ListGroup.Item>
    );    
}

export default function CorgillogicalHint({puzzleID, numHints}) {
    const [showHints, setShowHints] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [hints, setHints] = React.useState(null);

    const handleShowHints = () => {
        setIsLoaded(false);
        fetch('/puzzle/hint/?puzzle_id=' + puzzleID)
            .then(res => res.json())
            .then(
                  (payload) => {
                      setIsLoggedIn(payload.logged_in);
                      setHints(payload.hints);
                      setIsLoaded(true);
                  },
                  (error) => {
                      setIsLoaded(false);
                  }
            );
    };
    
    const renderBody = () => {
        if (!isLoaded) {
            return <Loading />;
        }

        if (hints && Object.entries(hints).length === 0) {
            return <p>There are no hints for this puzzle</p>;
        }
        
        if (!isLoggedIn) {
            return <p>Log in to view hints</p>;
        }

        return (
            <div>
                You can get hints about ... 

                <ListGroup variant="flush">
                    {Object.entries(hints).map(
                        ([hintID, hint]) =>
                            <HintItem
                                puzzleID={puzzleID}
                                hintID={hintID}
                                hint={hint}
                            />
                    )}
                </ListGroup>
            </div>
        );
    };

    return (
        <span>
            <Modal
                onHide={() => setShowHints(false)}
                onShow={handleShowHints}
                show={showHints}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Hints</Modal.Title>
                </Modal.Header>
                <Modal.Body>{renderBody()}</Modal.Body>
            </Modal>

            <a href="#" onClick={() => setShowHints(!showHints)}>
                <Lightbulb
                    className="puzzleHintIcon"
                    color={numHints === 0 ? 'lightgray' : 'royalblue'}
                    size={24}
                />
            </a>
        </span>
    );
}
