import React from 'react';
import { Button, Card, Col, Container, Form, Row} from 'react-bootstrap';

import Loading from '../common/Loading.js';
import { formatPrice } from '../common/Utils.js';

import ColorPatchFactory from './ColorPatch.js';
import SizeBox from './SizeBox.js';
import ApparelCartCheckout from './ApparelCartCheckout.js';

function ApparentCartItem({variant, quantity, onEdit, completed}) {
    const addedQuantityRef = React.useRef(quantity);
    const [quant, setQuant] = React.useState(quantity);

    // Probably should use the id to properly trigger rerender instead of this jonx.
    if (quantity != addedQuantityRef.current) {
        addedQuantityRef.current = quantity;
        setQuant(quantity);
    }

    let coverPath = variant.image;
    let desc = variant.desc;
    let price = variant.price;
    let sku = variant.sku;
    let size = variant.metadata.size;
    let color = variant.metadata.color;
    let itemName = variant.name;

    let noop = () => null;

    return (
        <Card className="apparelCard">
            <Card.Body>
                <Row>
                    {coverPath && <Col sm="4"><img id={'cart_img_' + sku} className="apparelImage" src={coverPath} /></Col>}
                    <Col sm="8">
                        <Row>
                            <Col><b>{itemName}</b></Col>
                        </Row>
                        <Row>
                            <Col><Form.Text muted>{desc}</Form.Text></Col>
                        </Row>
                        <Row>
                            <Col className="itemPrice">
                                {formatPrice(price)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="itemLabel" sm={4}> Color </Col>
                            <Col sm={6}>
                                <ColorPatchFactory
                                    activeColor={color}
                                    colorid={color}
                                    onEnter={noop}
                                    onLeave={noop}
                                    onSelect={noop}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="itemLabel" sm={4}>Size</Col>
                            <Col sm={6}>
                                <SizeBox
                                    activeSize={size}
                                    size={size}
                                    onEnter={noop}
                                    onLeave={noop}
                                    onSelect={noop}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="itemLabel" sm={4}> Quantity </Col>
                            <Col sm={6}>
                                <Form.Select
                                    className="itemSelect"
                                    size="sm"
                                    disabled={completed}
                                    value={quant}
                                    onChange={(e) => setQuant(e.target.value)}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} />
                            <Col sm={6}>
                                <Button
                                    disabled={completed || quantity === quant}
                                    size="sm"
                                    onClick={() => onEdit(sku, quant)}>
                                    Update Item
                                </Button>
                                <Button
                                    disabled={completed}
                                    variant="danger"
                                    size="sm"
                                    onClick={() => onEdit(sku, 0)}>
                                    Remove Item
                                </Button>                          
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default function ShopApparelCart({cart, onEdit, onComplete}) {
    const [completed, setCompleted] = React.useState(false);

    const handleComplete = () => {
        setCompleted(true);
        onComplete();
    };

    if (!cart || Object.values(cart).length == 0) {
        return <div>Your Cart is Empty. Add some Corgis!</div>
    }

    return (
        <>
        <Row xs={1} className="shopViewGroup">
            <Col><h2 className="cartTitle">Cart</h2></Col>
            <Col>
                (Warning: Cart will be emptied if the page is refreshed)
            </Col>
            {Object.values(cart).map(
                x => <Col key={'item_' + x.item.sku + '_' + x.quantity}>
                    <ApparentCartItem 
                        variant={x.item}
                        quantity={x.quantity}
                        onEdit={onEdit}
                        completed={completed}
                    />
                </Col>
            )}
            <Col>
                <ApparelCartCheckout
                    cart={cart}
                    onComplete={handleComplete}
                />
            </Col>
        </Row>
        </>
    );
}
