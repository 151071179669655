import { Facebook, Google } from 'react-bootstrap-icons';

export default function LoggedOutView({next}) {
    return (
        <div>
            You are not currently logged in.
            <br />
            <br />
            Log in to <b>get extra puzzles</b>, <b>track progress</b> and <b>challenge the leaderboard</b>!
            <br />
            <br />
            You can log in with
            <br />
            <a href={'/oauth/login/facebook/?next=' + next}>
                <Facebook /> Login with Facebook
            </a>
            <br />
            <a href={'/oauth/login/google-oauth2/?next=' + next}>
                <Google /> Login with Google
            </a>
        </div>
    );    
}