import React from 'react';

import './Common.css';

export function Checkmark({color='green', alt=''}) {
    let classNames = 'checkmark noselect ' + color;
    return (
        <div className={classNames} role="img" alt={alt}>{'\u2713'}</div>
    );
}
