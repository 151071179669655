import React from 'react';

import * as GridConst from './SokobanConstants.js';
import SokobanGrid from './SokobanGrid.js';


export default function SokobanGameBoard({
    gridData,
    onGameWon = null,
    onGameLost = null,
    onRestart = null,
    restartCount = 0,
    showInfo = true,
}) {
    const [result, setResult] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [stepCount, setStepCount] = React.useState(0);
    const [bestScore, setBestScore] = React.useState(0);
    const [grid, setGrid] = React.useState(gridData.getPlayGrid());
    const [state, setState] = React.useState({
        y: null,
        x: null,
        sx: null,
        sy: null,
        moves: [],
    });
    const moveStackRef = React.useRef([]);

    const updateState = (updatedState) => {
        setState(prevState => {
            let a = {...prevState, ...updatedState};
            return a;
        });
    };

    const setInitialGridState = React.useCallback(() => {
        if (gridData === null) {
            return;
        }

        setGrid(gridData.getPlayGrid());
        setStepCount(0);
        setResult(null);
	updateState({
            y: gridData.flag[0],
            x: gridData.flag[1],
            sx: null,
            sy: null,
            selected: false,
            moves: [],
        });
        setBestScore(gridData.par());
    }, [gridData]);

    // Refresh state upon receiving new gridData
    React.useEffect(() => {
        setIsLoaded(false); // TODO

        setInitialGridState()

        setIsLoaded(true);
    }, [gridData, setInitialGridState, restartCount]);

    const handleRestartLevelEvent = React.useCallback((event) => {
	setInitialGridState();
	setStepCount(0);
        setResult(null);
        moveStackRef.current = [];
        onRestart && onRestart(event);
    }, [setInitialGridState, onRestart]);

    const handleSelectCell = React.useCallback((cx, cy) => {
	let value = grid[cy][cx];	
	if (!isBlockedCell(value)) {
            return;
	}
	// The cell was already selected. Deslect by nullifying
	if (state.sx === cx && state.sy === cy) {
	    updateState({sx: null, sy: null});
	} else {
	    updateState({sx: cx, sy: cy});
	}
    }, [state, grid]);

    const getMouseOverCellCoordinate = (event) => {
	let target = event.target;
	let cell = target.closest('.sokobanCell');
	if (cell === null) { 
	    return null;
	}
	let i = parseInt(cell.getAttribute('data-i'));
	let j = parseInt(cell.getAttribute('data-j'));
	return [i, j];
    };

    const handleHoverCell = (event) => {
	let coord = getMouseOverCellCoordinate(event);
	if (coord === null) { 
	    return;
	}
	updateState({x: coord[1], y: coord[0]});
    };

    const handleTouchOrMouseMove = (event) => {
        // preventing default mobile behavior associated with dragging
	event.preventDefault();
    };

    const handleTouchStart = (event) => {
	if (result !== null) {
	    return;
	}

	let touches = event.changedTouches;
	if (touches.length !== 1) {
	    return;
	}
	event.preventDefault();
	
	let coord = getMouseOverCellCoordinate(event);
	if (coord === null) { 
	    return;
	}

	let mx = parseInt(touches[0].pageX);
	let my = parseInt(touches[0].pageY);
	updateState({mx: mx, my: my, sx: coord[1], sy: coord[0]});	
    };

    const handleDragMovement = (mx, my) => {
	let downX = state.mx;
	let downY = state.my;
	let sx = state.sx;
	let sy = state.sy;

	if (downX === null || downY === null ||
	    sx === null || sy === null) {
	    return;
	}

	let dx = mx - downX;
	let dy = my - downY;
	let adx = Math.abs(dx);
	let ady = Math.abs(dy);
	
	if (adx > 10 && adx >= ady * 5) {
	    // horizontal movement
	    handleMoveCell(sy, sx, 0, parseInt(dx / adx));
	} else if (ady > 10 && ady >= adx * 5) {
	    // vertical movement
	    handleMoveCell(sy, sx, parseInt(dy / ady), 0);
	}
    };

    const handleTouchEnd = (event) => {
	event.preventDefault();
	let touches = event.changedTouches;
	if (touches.length !== 1) {
	    return;
	}

	let mx = parseInt(touches[0].pageX);
	let my = parseInt(touches[0].pageY);
	handleDragMovement(mx, my);
    };

    const handleTouchCancel = (event) => {
	event.preventDefault();
	updateState({mx: null, my: null, sx: null, sy: null});	
    };

    const handleMouseDown = (event) => {
	if (result !== null) {
	    return;
	}

	let coord = getMouseOverCellCoordinate(event);
	if (coord === null) { 
	    return;
	}


	let mx = event.nativeEvent.x;
	let my = event.nativeEvent.y;

	updateState({mx: mx, my: my, sx: coord[1], sy: coord[0]});
    };

    const handleMouseUp = (event) => {
	if (result !== null) {
	    return;
	}
	
	let mx = event.nativeEvent.x;
	let my = event.nativeEvent.y;
	handleDragMovement(mx, my);
	updateState({sx: null, sy: null});
    };

    const isBlockedCell = (v) => {
	return v === GridConst.B || v === GridConst.F;
    };

    const handleMoveCell = React.useCallback((i, j, di, dj) => {
	let fi = i;
	let fj = j;
	let inbounds = true;
	let currGrid = grid;
	let currValue = currGrid[i][j];
	let steps = stepCount + 1;
        let moves = state.moves;

	// Cannot move non-blocks. Abort early
	if (!isBlockedCell(currValue)) {
            return;
	}
	
        let prevGrid = currGrid.map(r => r.slice());

	while (true) {
	    fi += di;
	    fj += dj;

	    // out of bounds
	    if (fi < 0 || fj < 0 ||
                fi >= gridData.getHeight() ||
                fj >= gridData.getWidth()) {
		inbounds = false;
		fi = i;
		fj = j;
		break;
	    }
	    let value = currGrid[fi][fj];
	    if (isBlockedCell(value)) {
		// backtrace to the cell right before as new location
		fi -= di;
		fj -= dj;
		break;
	    }
	}

	// regardless, original position becomes empty
	currGrid[i][j] = GridConst.C;

        let gi = gridData.goal[0];
        let gj = gridData.goal[1];
        let newMove = [i, j, di, dj];

	if (inbounds) {
            // set new location for the moved cell
            currGrid[fi][fj] = currValue;

	    // special case where nothing moved, then don't count as a step
	    if (fi === i && fj === j) {
		steps -= 1;
	    } else {
                moves.push(newMove);

                // winning condition
                if (fi === gi && fj === gj && currValue === GridConst.F) {
                    onGameWon && onGameWon(moves);
                    setResult(true);
                    if (moves.length < bestScore) {
                        setBestScore(moves.length);
                    }
                }
            }
	} else if (currValue === GridConst.F) {
            moves.push(newMove);
	    // if flag went out of bounds, game over
	    onGameLost && onGameLost(moves);
            setResult(false);
	} else {
            moves.push(newMove);
        }

        setStepCount(steps);
	updateState({
	    grid: currGrid,
	    x: fj,
            y: fi,
	    sx: null,
	    sy: null,
            moves: moves,
	});

        // Store the previous move for undo
        moveStackRef.current.push(prevGrid);
    }, [state, bestScore, grid, gridData, stepCount, onGameWon, onGameLost]);


    const handleUndoMove = React.useCallback(() => {
        // Nothing to undo
        if (moveStackRef.current.length === 0) {
            return;
        }
        
        // Undo by restoring the previous grid and popping a move
        let prevGrid = moveStackRef.current.pop();
        let moves = state.moves;
        moves.pop();

        updateState({moves: moves})
        setResult(null);
        setGrid(prevGrid);
        setStepCount(stepCount - 1);
    }, [state, stepCount]);

    const handleKeyDown = React.useCallback((event) => {
	let kc = event.keyCode;
	switch (kc) {
	case 32: // space
            if (result !== null) {
                return;
            }

            handleSelectCell(state.x, state.y);
	    break;
	         // key   kc%4 tkc tkc/2 tkc%2
	         // ---------------------------
	case 37: // left   1    2    1     0
	case 38: // up     2    3    1     1
	case 39: // right  3    0    0     0
	case 40: // down   0    1    0     1
            if (result !== null) {
                return;
            }
	    let tkc = (kc + 1) % 4;
	    let xORy = tkc % 2; // 0 is left/right 1 is up/down
	    let pORm = Math.floor(tkc / 2); // 0 is - 1 is +

	    // current positions
	    let cx = state.x;
	    let cy = state.y;
	    
	    let mult = pORm === 0 ? 1 : -1;
	    let dx = xORy === 0 ? mult : 0;
	    let dy = xORy === 1 ? mult : 0;

            let width = gridData.getWidth();
            let height = gridData.getHeight();
	    if (state.sy === null && state.sx === null) {
		// updated positions
		let nx = (width + cx + dx) % width;
		let ny = (height + cy + dy) % height;
		// issue update
		updateState({x: nx, y: ny});
	    } else {
		handleMoveCell(cy, cx, dy, dx);
	    }

	    break;
        case 82: // r
            handleRestartLevelEvent(event);
            break;
        case 90: // z
            handleUndoMove(event);
            break;
        default:
            break;
	}
    }, [state, gridData, result, handleMoveCell, handleRestartLevelEvent, handleSelectCell]);

    const renderLevelInfo = () => {
        if (!showInfo) {
            return null;
        }
	let targetSteps = bestScore; // gridData.par();
        targetSteps = targetSteps === 0 ?
          'none' :
          <><b>{targetSteps}</b> steps</>;

	return (
            <div>
                <h2 className="sokobanHeader">
                    {gridData.getDesc()}
                </h2>
                {gridData.expiry ? <div className="puzzleExpiry">{gridData.expiry}</div> : null}
	        <span className="stepCount">
                    Best Score: {targetSteps}
	        </span>
	        <span className="stepCount">
   	            Current: <b>{stepCount}</b> steps
	        </span>
	    </div>
	);
    };

    const handleTouchMove = (e) => e.preventDefault();

    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
	document.addEventListener("touchmove", handleTouchMove, {passive: false});
        return () => {
            // unsubscribe event
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("touchmove", handleTouchMove);
        };
    }, [handleKeyDown]);

    // TODO Refactor shared loading screen
    if (!isLoaded) {
        return (
            <h2 className="sokobanHeader">
                loading...
            </h2>
        );
    }

    if (gridData === null) {
        return (
            <div>
                <h2 className="sokobanHeader">
                    This level does not exist, please select another one.
                </h2>
            </div>
        );
    }

    if (gridData.expired) {
        return (
            <div>
                <h2 className="sokobanHeader">
                    Sorry, this level has expired and is not currently available.
                </h2>
            </div>
        );
    }

    return (
        <div>
	    {renderLevelInfo()}

            <SokobanGrid
                grid={grid}
                gridData={gridData}
                gameOver={result !== null}
                handleHoverCell={handleHoverCell}
                handleTouchStart={handleTouchStart}
   	        handleTouchOrMouseMove={handleTouchOrMouseMove}
	        handleTouchEnd={handleTouchEnd}
   	        handleTouchCancel={handleTouchCancel}
                handleMouseDown={handleMouseDown}
                handleMouseUp={handleMouseUp}
                activeCell={[state.y, state.x]}
                cellSelected={state.sy === state.y && state.sx === state.x}
            />
        </div>
    );

}