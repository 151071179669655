import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

import Loading from '../common/Loading.js';

export function ShopEventCard({event, past=false}) {
    let coverPath = event.image;
    let desc = event.desc;
    let eventLink = '/event/?event_id=' + event.metadata.eventid;

    return (
        <Card className={past ? 'pastEvent' : 'activeEvent'}>
            {coverPath && <Card.Img variant="top" src={coverPath} />}

            <Card.Body>
              <Card.Text>
                  {desc}
              </Card.Text>

              <Button
                  href={eventLink}
                  variant="primary">
                  View Event Detail
              </Button>

            </Card.Body>
          </Card>

    );
}

export default function ShopEventView({ events }) {
    let activeEvents = [];
    let pastEvents = [];    

    for (const k in events) {
        let ev = events[k][0];
        (ev.active ? activeEvents : pastEvents).push(ev);
    }

    if (!events) {
        return ;
    }

    
    return (
        <>
            <Row xs={1} md={2} style={{marginTop: '12px'}}>
                {!activeEvents ? 
                    <Col> There are currently no ongoing events </Col> :
                    activeEvents.map(x => <Col><ShopEventCard event={x} /></Col>)
                }
            </Row>


            <Row lg={1} style={{marginTop: '12px'}}>
                <h2>Past Events</h2>
            </Row>

            <Row xs={1} md={2} style={{marginTop: '12px'}}>
                {pastEvents.map(
                    x => <Col><ShopEventCard event={x} past={true} /></Col>
                )}
            </Row>
        </>
    );
}

