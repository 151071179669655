import React from 'react';
import { Table } from 'react-bootstrap';

import Loading from '../common/Loading.js';
import RenderRank from '../common/RankDisplay.js';

function CorgiPlayerListRow({player}) {
    return (
        <tr>
            <td>{RenderRank(player.rank)}</td>
            <td>
                <a href={'/puzzle/player/?player_id=' + player.id}>
                    {player.name}
                </a>
            </td>
            <td>{player.numSolved}</td>
        </tr>
    );
}

export default function CorgillogicalLeaderboard() {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [playerList, setPlayerList] = React.useState([]);
    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        setIsLoaded(false);
        fetch('/puzzle/fetch_leaderboard/')
            .then(res => res.json())
            .then(
                  (payload) => {
                      setIsLoaded(true);
                      setPlayerList(payload.playerList)
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, []);

    if (!isLoaded) {
        return <Loading />;
    }

    const renderTableHeader = () => {
        // This is the full leaderboard across all puzzles
        return (
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Name</th>
                    <th>Puzzles Solved</th>
                </tr>
            </thead>
        );
    };

    const renderTableBody = () => {
        return (
            <tbody>
                {playerList.map((player) =>
                    <CorgiPlayerListRow
                        key={'player_row_' + player.id}
                        player={player}
                    />
                )}
            </tbody>
        );
    };

    return (
        <div>
            <h2 className="sokobanHeader">
                Top Scores
            </h2>
            <Table striped hover size="sm" className="sokobanListTable">
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        </div>
    );
}