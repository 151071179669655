import './ResultModal.css';

import React from 'react';
import { Modal } from 'react-bootstrap';

import { classes } from './Utils.js';
import { ResultViewFooter } from './Footer.js';


export default function ResultModal({
    result,
    onHide = null,
    winText="Correct!",
    loseText="Incorrect!",
}) {

    const [show, setShow] = React.useState(false);
    const [status, setStatus] = React.useState(result);
    const [message, setMessage] = React.useState('');

    React.useEffect(() => {
        setShow(result !== null);

        // Don't update the win/lose status unless it explicitly changed
        // e.g. result set to true/false.
        if (result !== null) {
            setStatus(result);
            setMessage(result ? winText : loseText);
        }
    }, [result, winText, loseText]); 

    let cls = classes({
        resultModal: true,
        win: status === true,
        lose: status === false,
        resultWithNotes: loseText.length > 20,
    });

    const onModalHide = () => {
        if (onHide !== null) {
            onHide();
        }
    };

    return (
        <Modal
            className={cls}
            show={show}
            onExit={onModalHide}
            onHide={() => setShow(null)}
            backdrop="true">
            <div onClick={() => setShow(null)}>
                <div className="resultIconContainer">
                    <div className="resultIcon" />
                </div>
                <div className="resultText">
                    {message}
                </div>

                <ResultViewFooter />
            </div>
        </Modal>
    );
}