import './Home.css';

import YouTube from 'react-youtube';
import { Card, Col, Container, Figure, Row  } from 'react-bootstrap';

export default function Home() {
    const longFormOpts = {
        height: '320',
        width: '580',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: false,
        },
    };

    const shortFormOpts = {
        height: '320',
        width: '180',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: false,
        },
    };

    const handleOnReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    };

    return (
        <Container>
            <Card>
                <Card.Body>
                <Row className="introBlock">
                <Col sm={3}>
                    <img 
                        className="corgiImage"
                        src="/static/corgillogical.jpeg"
                        alt="Cute Corgi"
                    />
                </Col>
                <Col className="henloTextContainer">
                    <h2 className="henloHeader"><b>Henlo!</b></h2> {"I'm"} Cocoa, a fluffy corgi!
                    {' '} I live in the Bay Area in California, love playing, solving and creating puzzles, and loves music!

                    <br /><br />

                    If you enjoy these puzzles and would like more, reach out and let me know!
                    {' '}You may also reach out if you would like custom puzzles created for your event (birthday parties, wedding, team building events, etc) .

                    <br /><br />
            
                    We also have a simple ticketing and payment system (like eventbrite) that we used to host local band performances. If you would like to leverage the system, feel free to reach out as well!

                    <br /><br />

                    Here you can play a <a href="sokoban/">logic puzzle game called Sokoban</a> and also 
                    solve <a href="puzzle/">variety puzzles here</a>.
                    <br /><br />
                    You can also visit and follow my <a href="https://youtube.com/@corgillogical">youtube channel @corgillogical</a> to see my cute antics and adventures,
                    or visit mai instagwam {' '}
                    <a href="https://www.instagram.com/corgillogical/">@corgillogical</a>
                    {' / '}
                    <a href="https://www.instagram.com/burnttoastcocoa/">@burnttoastcocoa</a>.

                </Col>
            </Row>
            </Card.Body>
            </Card>

            <Card className="introBox">
                <Card.Header>Some of my videos</Card.Header>
                <Card.Body>
            <Row className="videoContainer">
                <Col>
                    <YouTube videoId="S07nCuANZiw" opts={longFormOpts} onReady={handleOnReady} />
                </Col>
                <Col>
                    <YouTube videoId="A0GTYNJuSHU" opts={shortFormOpts} onReady={handleOnReady} />
                </Col>
                <Col>
                    <YouTube videoId="BD7frMp04nU" opts={shortFormOpts} onReady={handleOnReady} />
                </Col>
                <Col> 
                    <YouTube videoId="nxfvB-vDMcg" opts={shortFormOpts} onReady={handleOnReady} />
                </Col>

            </Row>
                </Card.Body>
            </Card>

            <Card className="introBox">
                <Card.Header>Some of the puzzle events</Card.Header>
            <Card.Body>

        
            <Row className="videoContainer">
                <Col>
                    <Figure>
                      <Figure.Image
                        width={360}
                        height={200}
                        alt="Solving puzzles before reception. A unique way to celebrate and share your story while entertaining your wedding guests!"
                        src="/static/puzzle_wedding_1.jpeg"
                      />
                      <Figure.Caption>
                          Solving puzzles before reception. A unique way to celebrate and share your story while entertaining your wedding guests!
                      </Figure.Caption>
                    </Figure>
                </Col>

                <Col>
                    <Figure>
                      <Figure.Image
                        width={360}
                        height={200}
                        alt="Solving a physical puzzle at the conclusion of the wedding puzzle hunt."
                        src="/static/puzzle_wedding_2.jpeg"
                      />
                      <Figure.Caption>
                          Solving a physical puzzle at the conclusion of the wedding puzzle hunt.
                      </Figure.Caption>
                    </Figure>
                </Col>

                </Row>
                </Card.Body>
            </Card>


        
            <Card className="introBox">
                <Card.Header>Contact Cocoa</Card.Header>
                <Card.Body>
                    <Card.Text>
                        You can reach me at <a href="mailto:cocoa@corgillogical.com">cocoa@corgillogical.com</a>. 
                    </Card.Text>
                </Card.Body>
            </Card>

            <br />
        </Container>
    );
}
