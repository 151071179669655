import React from "react";

import { classes } from '../common/Utils.js';

import * as GridConst from './SokobanConstants.js';


function SokobanCell({grid, gridData, row, col, activeCell, cellSelected, handleHoverCell}) {
    let isActive = activeCell === null ?
        false : (activeCell[0] === row && activeCell[1] === col);
    let value = grid[row][col];
    let isFlag = value === GridConst.F;
    let isGoal = gridData.isGoal(row, col);

    let classNames = classes({
        sokobanCell: true,
        hover: isActive,
        selected: isActive && cellSelected,
        block: value === GridConst.B,
        goal: isGoal,
        flag: isFlag,
    });

    return (
        <div
            className={classNames}
            data-i={row}
            data-j={col}
            key={'cell_' + row + '_' + col}
            onMouseEnter={handleHoverCell}>
    	    <div className="sokobanInnerCell">
                {isFlag ? GridConst.FLAG_EMOJI : (isGoal ? GridConst.GOAL_EMOJI : '')}
            </div>
        </div>
    );
} 

function SokobanRow({grid, gridData, activeCell, cellSelected, row, handleHoverCell}) {
    let cells = [];
    for (let j = 0; j < gridData.getWidth(); j++) {
        cells.push(
            <SokobanCell
                grid={grid}
                gridData={gridData}
                activeCell={activeCell}
                cellSelected={cellSelected}
                row={row}
                col={j}
                handleHoverCell={handleHoverCell}
            />
        );
    }
    return (
        <div className="sokobanRow" key={'row_' + row}>
            {cells}
        </div>
    );
}

export default function SokobanGrid({
    grid,
    gridData,
    activeCell = null,
    cellSelected = false,
    handleTouchStart = null,
    handleTouchOrMouseMove = null,
    handleTouchEnd = null,
    handleTouchCancel = null,
    handleMouseDown = null,
    handleMouseUp = null,
    handleHoverCell = null,
    gameOver = false,
}) {    
    if (gridData.expired) {
        return null;
    }
    let rows = [];
    for (let i = 0; i < gridData.getHeight(); i++) {
        rows.push(
            <SokobanRow
                grid={grid}
                gridData={gridData}
                activeCell={activeCell}
                cellSelected={cellSelected}
                row={i}
                handleHoverCell={handleHoverCell}
            />
        );
    }
    let cx = classes({
        sokobanGrid: true,
        gameOver: gameOver,
    });
    
    return (
        <div
            className={cx}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchOrMouseMove}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={handleTouchCancel}
            onMouseDown={handleMouseDown}
            onMouseMove={handleTouchOrMouseMove}
            onMouseUp={handleMouseUp}>
            {rows}
        </div>
    );
}
