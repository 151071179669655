import React from 'react';
import { Button, Card, Container, ListGroup } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';



import Loading from '../common/Loading.js';
import { getWithData } from '../common/SecurityUtils.js';

function EventCard({event}) {
    let coverPath = event.image;
    let desc = event.desc;
    let eventLink = '/event/?event_id=' + event.metadata.eventid;

    return (
        <Card className="eventPreviewCard">
            {coverPath && <Card.Img className="eventCover" variant="top" src={coverPath} />}

            <Card.Body>
              <Card.Text>
                  {desc}
              </Card.Text>

              <Button
                  href={eventLink}
                  target="_blank"
                  variant="primary">
                  View Event
              </Button>

            </Card.Body>
          </Card>

    );
}

export default function EventRecommendationsList({eventID}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [events, setEvents] = React.useState(null);

    React.useEffect(() => {
        getWithData('/shop/fetch_event_rec/', {current_event_id: eventID})
            .then(res => res.json())
            .then(
                  (payload) => {
                      setEvents(payload);
                      setIsLoaded(true);
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, []);

    
    if (!isLoaded) {
        return <Loading />;
    }

    if (Object.keys(events).length === 0) {
        return null;
    }

    return (
        <div className="mt-4">
            <h2> Other Upcoming Events </h2>
            <ListGroup
                className="eventRecList"
                variant="flush"
                horizontal
                style={{
                    overflowX: "scroll"
                }}>
                {Object.keys(events).map(ei =>
                    <ListGroup.Item className="list_item" key={ei}>
                        <EventCard event={events[ei]} />
                    </ListGroup.Item>
                )}
            </ListGroup>
        </div>
    );
    
}
