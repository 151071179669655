import './Corgillogical.css';

import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

import { postWithToken } from '../common/SecurityUtils.js';
import PuzzleToast from '../common/PuzzleToast.js';
import ResultModal from '../common/ResultModal.js';
import SiteURL from '../common/SiteURL.js';

import CorgillogicalPuzzle from './CorgillogicalPuzzle.js';

export default function CorgillogicalPackItem({puzzleData, active, onUnlockPuzzle}) {
    let packID = puzzleData.group.group_id;
    let puzzleID = puzzleData.puzzle_id;

    const [answer, setAnswer] = React.useState('');
    const [prevAnswer, setPrevAnswer] = React.useState('');

    const [showShareToast, setShowShareToast] = React.useState(false);
    const [result, setResult] = React.useState(null);
    const [note, setNote] = React.useState(null);

    // Submit answer handler
    const handleSubmitAnswer = React.useCallback(() => {
        // Don't bother wasting a hit to the server
        if (answer === '' || answer === prevAnswer) {
            return;
        }
        const data = {
            'puzzle': puzzleID,
            'answer': answer,
        };

        setPrevAnswer(answer);
        postWithToken('/puzzle/submit/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setResult(res['result']);
                    setNote(res['note']);

                    if (res['unlocked']) {
                        setTimeout(onUnlockPuzzle, 1000);
                    }
                },
                (error) => {
                    console.log(error);
                    // set previous answer to null so the UI won't prevent
                    // resubmission due to server-side failure.
                    setPrevAnswer(null);
                },
            );        
        }, [answer, prevAnswer, puzzleID]);


    const handleKeyDown = React.useCallback((event) => {
        if (!active) {
            return;
        }
        let kc = event.keyCode;
        switch (kc) {
        case 13: // enter
            handleSubmitAnswer();
            break;
        default:
            break;
        }
    }, [handleSubmitAnswer, active]);

    const handleSharePuzzle = () => {
        let message = 'Can you solve this puzzle?\n';
        message += new SiteURL()
            .corgillogical()
            .setParams({pack:packID, puzzle_id:puzzleID})
            .getAbsolute();
        navigator.clipboard.writeText(message);
        setShowShareToast(true);
    };

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            // unsubscribe event
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <div className="puzzleView">
            <PuzzleToast
                onClose={() => setShowShareToast(false)}
                show={showShareToast}
                text="Message copied to clipboard!"
            />

            <ResultModal
                result={result}
                onHide={() => setResult(null)}
                winText={note ?? 'Correct!'}
                loseText={note ?? 'Incorrect!'}
            />

            <div className="puzzleContainer">
                <CorgillogicalPuzzle puzzleData={puzzleData} />
            </div>

            <InputGroup>
                <Form.Control
                    required
                    type="text"
                    placeholder="Answer"
                    aria-label="Puzzle's Answer"
                    value={answer}
                    onChange={e => setAnswer(e.target.value)}
                />
                <Button
                    disabled={answer === prevAnswer}
                    variant="primary"
                    onClick={handleSubmitAnswer}>
                    Submit
                </Button>
                <Button variant="outline-secondary" onClick={handleSharePuzzle}>
                    Share
                </Button>
            </InputGroup>
        </div>
    );
}
