import React from "react";
import { Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { QuestionCircle } from 'react-bootstrap-icons';

import CommonNavbar from '../common/CommonNavbar.js';


export default function ShopHeader() {
    /*
                    <LinkContainer to="/shop/events/">
                        <Nav.Link>Events</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/shop/apparel/">
                        <Nav.Link>Apparel</Nav.Link>
                    </LinkContainer>
                    <Nav.Link active={false} onClick={() => 1}>
                        <QuestionCircle />
                    </Nav.Link>

     */
    return (
        <>
            <CommonNavbar className="sokobanNavbar" title="Shop">
                <Nav>
                </Nav>
            </CommonNavbar>
        </>
    );
}
