import React from 'react';
import { Container } from 'react-bootstrap';

import Loading from '../common/Loading.js';


export default function QuizListView({quizID}) {
    return (
        <div>
            Nothing to see here!
        </div>
    );
}
