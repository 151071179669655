function getParamStr(params) {
    return Object.entries(params).map(([k,v]) => k + '=' + v).join('&');
}

export default class SiteURL {

    constructor(path='') {
        this.path = path;
        this.params = {};
        this.secure = true;
        this.product = null;
    }

    sokoban() {
        this.product = 'sokoban';
        return this;
    }

    corgillogical() {
        this.product = 'puzzle';
        return this;
    }

    event() {
        this.product = 'event';
        return this;
    }

    setParams(params) {
        this.params = params;
        return this;
    }

    getRelative() {
        let p = ['', this.product, this.path]
            .filter(n => n !== null).join('/');
        return [p, getParamStr(this.params)].join('?');
    }

    getAbsolute() {
        return (this.secure ? 'https://' : 'http://') +
            window.location.hostname +
            this.getRelative();
    }
}
