import { Container } from 'react-bootstrap';
import {Routes, Route, useSearchParams} from 'react-router-dom';

import MyAccountView from '../common/MyAccountView.js';
import NotFound from '../home/NotFound.js';

import Corgillogical from './Corgillogical.js';
import CorgillogicalAccountView from './CorgillogicalAccountView.js';
import CorgillogicalHeader from './CorgillogicalHeader.js';
import CorgillogicalLeaderboard from './CorgillogicalLeaderboard.js';
import CorgillogicalLevels from './CorgillogicalLevels.js';
import CorgillogicalPlayerView from './CorgillogicalPlayerView.js';

export default function CorgillogicalAppRouter() {
    let [searchParams] = useSearchParams();
    let puzzle = searchParams.get('puzzle');
    let pack = searchParams.get('level_id');
    let playerID = searchParams.get('player_id');

    return (
        <div>
            <CorgillogicalHeader />

            <div className="AppBody">
            <Container className="AppContainer">

                <Routes>
                    <Route path='*' element={<NotFound />} />

                    <Route
                        index 
                        element={
                            <Corgillogical 
                                key={'puzzle_' + puzzle}
                                puzzle={puzzle}
                            />
                        }
                    />
                    <Route 
                        path='levels'
                        element={
                            <CorgillogicalLevels key={'pack_' + pack} pack={pack} />
                        }
                    />
                    <Route path="leaderboard" element={<CorgillogicalLeaderboard />} />
                    <Route path="account" element={
                        <MyAccountView next="/puzzle/account/">
                            <CorgillogicalAccountView />
                        </MyAccountView>
                    } />
                    <Route path="player" element={<CorgillogicalPlayerView playerID={playerID} />} />
                </Routes>

            </Container>
            </div>
        </div>
    );
}
