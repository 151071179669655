import React from "react";
import { Button } from 'react-bootstrap';

import { postWithToken } from '../common/SecurityUtils.js';
import SiteURL from '../common/SiteURL.js';

import { SokobanLevel } from './SokobanLevel.js';
import SokobanGrid from './SokobanGrid.js';
import * as GridConst from './SokobanConstants.js';

export default function SokobanEditorView() {
    const makeNewGrid = (w, h) => new Array(h).fill(0).map(() => new Array(w).fill(0));
    const [dummy, setDummy] = React.useState(0);
    const [width, setWidth] = React.useState(8);
    const [height, setHeight] = React.useState(8);
    const [name, setName] = React.useState('PuzzleID');
    const [desc, setDesc] = React.useState('Puzzle Name');
    const [group, setGroup] = React.useState('easy');
    const [privacy, setPrivacy] = React.useState('');
    const [grid, setGrid] = React.useState(makeNewGrid(width, height));
    const [level] = React.useState(() => {
        let l = new SokobanLevel(name, desc, group, grid, 0);
        return l.init();
    });
    const [hasFlag, setHasFlag] = React.useState(false);
    const [hasGoal, setHasGoal] = React.useState(false);
    const [dailyOffset, setDailyOffset] = React.useState(0);

    const handleResizeGrid = (w, h) => {
        let g = makeNewGrid(w, h);
        for (let i = 0; i < h && i < level.height; i++) {
            for (let j = 0; j < w && j < level.width; j++) {
                g[i][j] = level.grid[i][j];
            }
        }

        setWidth(w);
        setHeight(h);
        setGrid(g);
        level.width = w;
        level.height = h;
        level.grid = g;
    };

    const handleWidthChange = (event) => {
        handleResizeGrid(parseInt(event.target.value), height);
    };
    const handleHeightChange = (event) => {
        handleResizeGrid(width, parseInt(event.target.value));
    };
    const handleGroupChange = (event) => {
        let group = event.target.value;
        level.group = group
        setGroup(group);
    };    
    const handleNameChange = (event) => {
        let name = event.target.value;
        level.name = name;
        setName(name);
    };    
    const handleDescChange = (event) => {
        let desc = event.target.value;
        level.desc = desc;
        setDesc(desc);
    };    
    const handlePrivacyChange = (event) => {
        let row = event.target.value;
        setPrivacy(row);
    };    
    const handleMouseUp = (event) => {
	let target = event.target;
	let cell = target.closest('.sokobanCell');
	if (cell === null) { 
	    return null;
	}        
	let i = parseInt(cell.getAttribute('data-i'));
	let j = parseInt(cell.getAttribute('data-j'));
        
        // Stupid logic to cycle block state. Chunks of logic is to
        // ensure uniqueness of goals and flags.
        let v = grid[i][j];

        // Unset flag/goal if we click on them
        if (v === GridConst.F) {
            setHasFlag(false);
        } else if (v === GridConst.G) {
            setHasGoal(false);
        }

        // increment to the next thing
        v += 1;
        if (v === GridConst.F) {
            // If we became a flag but there's already one, move on.
            if (hasFlag) {
                v += 1;
            } else {
                setHasFlag(true);
            }
        }

        if (v === GridConst.G) {
            // If we became a goal but there's already one, move on.
            if (hasGoal) {
                v += 1
            } else {
                setHasGoal(true);
            }
        }

        
        grid[i][j] = v % 4;

        level.grid = grid;
        setGrid(grid);

        // lazy way to force react rerender
        setDummy(dummy + 1);
    };

    const handleSave = () => {
        let data = level.serialize();
        data['privacy_row'] = JSON.parse(privacy);

        postWithToken('/sokoban/create/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    console.log(res);
                },
                (error) => {
                    console.log(error);
                },
            );        
    };

    const handleDailyChange = (event) => {
        const offset = parseInt(event.target.value);
        setDailyOffset(offset);

        let now = new Date();
        now.setHours(5, 0, 0, 0); // set to 5am PST, in alignment with the daily puzzle release schedule

        let start = now.getTime() / 1000 + offset * 86400;
        let end = start + 7 * 86400; // 7 days after start

        let startDate = new Date(start * 1000);
        let mm = (startDate.getMonth() + 1 + '').padStart(2, '0');
        let dd = (startDate.getDate() + '').padStart(2, '0');
        let yyyy = startDate.getFullYear() + '';
        
        let refID = 99950;
        let refUnix = 1652875200;
        let currID = refID - ((start - refUnix) / 86400);

        let puzzleID = 'LD' + currID + '_' + yyyy + mm + dd;
        let puzzleName = 'Daily Puzzle ' + mm + '/' + dd + '/' + yyyy;

        let privacyRow = {
            _type: 1,
            rules: [
                {_type: 7},
                {
                    _type: 6,
                    start: start,
                    end: end,
                },
                {_type: 2},
            ]
        };

        level.name = puzzleID;
        level.desc = puzzleName;
        level.group = 'daily';
        setPrivacy(JSON.stringify(privacyRow));
        setName(puzzleID);
        setDesc(puzzleName);
    };

    const handleLoad = (name) => {
        // name
        let url = new SiteURL('fetch/').sokoban().setParams({puzzle_id: name});
        fetch(url.getRelative())
            .then(res => res.json())
            .then(
                (res) => {
                    console.log(res);
                    // Puzzle did not exist (or did not load)
                    if (!(name in res)) {
                        return;
                    }

                    const puzzleData = res[name].puzzle;
                    const grid = puzzleData.grid;

                    level.width = grid[0].length;
                    level.height = grid.length;
                    level.grid = grid;
                    level.group = puzzleData.group;
                    level.desc = puzzleData.desc;

                    setHeight(grid.length);
                    setWidth(grid[0].length);
                    setGrid(grid);
                    setDesc(puzzleData.desc);
                    setGroup(puzzleData.group);

                    let flag = false;
                    let goal = false;
                    for (let r of grid) {
                        for (let v of r) { 
                            if (v === GridConst.F) {
                                flag = true;
                            } else if (v === GridConst.G) {
                                goal = true;
                            }
                        }
                    }
                    setHasFlag(flag);
                    setHasGoal(goal);

                    setDummy(dummy + 1);
                },
                (error) => {
                    console.log(error);
                },
            );        
    };

    const handleGenerate = () => {
        handleLoad('autogeneratedcrap');
    };

    const handleCopyData = () => {
        let data = level.serialize();
        navigator.clipboard.writeText(JSON.stringify(data));
    }    
    
    return (
        <div>
            <h2 className="sokobanHeader">
                Sokoban Editor
            </h2>

            <label>
                Level ID: {' '}
                <input type="text" size="10" value={name} onChange={handleNameChange} />
            </label>
            <Button
                variant="primary"
                onClick={() => handleLoad(name)}>
                Load
            </Button>
            {' '}
            <label>
                Level Name: {' '}
                <input type="text" value={desc} onChange={handleDescChange} />
            </label>

            <div>
                Dimensions: 
                <select
                    name="width"
                    onChange={handleWidthChange}
                    value={width}>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                </select>
                {' X '}
                <select
                    name="height"
                    onChange={handleHeightChange}
                    value={height}>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                </select>

                <label>
                    Group: {' '}
                    <input type="text" size="10" value={group} onChange={handleGroupChange} />
                </label>
            </div>
            <div>
                <select
                    name="daily"
                    onChange={handleDailyChange}
                    value={dailyOffset}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                </select>

                <label>
                    Privacy Row: {' '}
                    <input type="text" size="20" value={privacy} onChange={handlePrivacyChange} />
                </label>             
            </div>

            <SokobanGrid
                key={'grid_' + dummy}
                grid={grid}
                gridData={level}
                handleMouseUp={handleMouseUp}
            />

            <span>
                <Button
                    variant="primary"
                    onClick={handleSave}>
                    Save
                </Button>
                {' '}
                <Button
                    variant="light"
                    onClick={handleGenerate}>
                    Generate
                </Button>
                {' '}
                <Button
                    variant="secondary"
                    onClick={handleCopyData}>
                    Copy Data
                </Button>
            </span>
        </div>
    );
}
