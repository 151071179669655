import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { classes } from '../common/Utils.js';


export function ColorPatch({activeColor, colorid, color, name, onEnter, onLeave, onSelect}) {
    const boxClass = classes({
        colorPatchBox: true,
        selected: activeColor == colorid,
    });

    return (
        <OverlayTrigger overlay={<Tooltip>{name}</Tooltip>}>
            <div
                className={boxClass}
                onClick={() => onSelect(colorid)}
                onMouseEnter={() => onEnter(colorid)}
                onMouseLeave={onLeave}
                style={{backgroundColor:color}} 
            />
        </OverlayTrigger>
    );
}

export default function ColorPatchFactory(props) {
    switch (props.colorid) {
    case 'Black': return <ColorPatch {...props} color="#000000" name="Black" />;
    case 'Grey': return <ColorPatch {...props} color="#c5c6cb" name="Heather Grey" />;
    case 'White': return <ColorPatch {...props} color="#f2f2f2" name="White" />;
    case 'Navy': return <ColorPatch {...props} color="#2b3043" name="Navy" />;
    default: return null;
    }
}
