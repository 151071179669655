import React from 'react';
import { Alert, Badge, Button, Card, Col, Row, Table } from 'react-bootstrap';
import Loading from '../common/Loading.js';
import { postWithToken } from '../common/SecurityUtils.js';
import { classes } from '../common/Utils.js';

import { Html5QrcodeScanner } from 'html5-qrcode';


const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
    let config = {};
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }
    return config;
};


const Html5QrcodePlugin = (props) => {
    React.useEffect(() => {
        // when component mounts
        const config = createConfig(props);
        const verbose = props.verbose === true;
        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
        html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, []);

    return (
        <div id={qrcodeRegionId} className="qrScanner" />
    );
};

export default function EventTicketScanner({eventID}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [ticketData, setTicketData] = React.useState(null);
    const [canAdmin, setCanAdmin] = React.useState(false);
    const [error, setError] = React.useState();
    const [admitted, setAdmitted] = React.useState(false);
    const [admitCount, setAdmitCount] = React.useState(0);
    const [scan, setScan] = React.useState('');
    
    const handleAdmit = React.useCallback((td) => {
        setIsLoaded(false);
        const data = {
            'event': td.event_id,
            'ticket': td.id,
        };

        postWithToken('/event/admit_ticket/', data)
            .then(res => res.json())
            .then(
                (payload) => {
                    setIsLoaded(true);
                    let err = payload.error ?? payload.msg;
                    setError(err);

                    // Successful admittance
                    setAdmitted(!err);
                    if (!err) {
                        setAdmitCount(admitCount + 1);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    let err = error.error ?? error.msg;
                    setError(err);
                },
            );        
        
    }, [admitCount]);
    

    const onScan = React.useCallback((ticketH, raw) => {
        let endpoint = '/event/fetch_ticket/';
        if (ticketH) {
            endpoint += '?h=' + ticketH;
        } else {
            endpoint += '?r=' + raw;
        }
        
        fetch(endpoint)
            .then(res => res.json())
            .then(
                (payload) => {
                    if ('ticket' in payload) {
                        setTicketData(payload.ticket);
                        setCanAdmin(payload.canAdmin);
                        setError(null);
                    } else if ('error' in payload) {
                        setError(payload.error);
                        setTicketData(null);
                        setCanAdmin(false);
                    }
                    setIsLoaded(true);

                    setAdmitted(false);
                    if (payload.canAdmin) {
                        handleAdmit(payload.ticket);
                    }
                },
                (error) => {
                    setError('Invalid Ticket');
                    setTicketData(null);
                    setCanAdmin(false);
                    setAdmitted(false);
                    setIsLoaded(true);
                }
            );
        
    }, [scan]);
    
    const onNewScanResult = React.useCallback((decodedText, decodedResult) => {
        let ticketH = null;

        try {
            const urlParams = new URL(decodedText).searchParams;
            ticketH = urlParams.get('h');
        } catch (ex) {
        }
        let lol = document.getElementById('qrHack').innerText;
        if (decodedText == lol) {
            return;
        }

        setScan(decodedText);
        setIsLoaded(false);
        onScan(ticketH, decodedText);
    }, [scan, onScan]);

    const renderTicketData = () => {
        if (!isLoaded) {
            return <Loading />;
        }
        let v = error ? 'danger' : (admitted ? 'success' : 'dark');
        return (
            <Alert variant={v}>
                {error && <p>{error}</p>}
                {admitted && <p>Valid Ticket!</p>}
                {ticketData &&
                    <Card>
                        <Card.Body>                    
                            Ticket for <b>{ticketData.event_name}</b>
                            <br />
                            Ticket Reserved By {ticketData.reserver}
                            {ticketData.complimentary &&
                             <div><Badge bg="warning">Complimentary Ticket</Badge></div>
                            }
                        </Card.Body>
                    </Card>
                }
            </Alert>
        );
    };
    
    return (
        <div>
            <span id="qrHack" style={{display: "none"}}>{scan}</span>
            {renderTicketData()}
            <Html5QrcodePlugin
                fps={5}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
            />
        </div>
    );
}
