import { Container } from 'react-bootstrap';
import {Routes, Route, useSearchParams} from 'react-router-dom';

import NotFound from '../home/NotFound.js';
import CommonNavbar from '../common/CommonNavbar.js';

import QuizHeader from './QuizHeader.js';
import QuizView from './QuizView.js';

export default function QuizAppRouter() {
    let [searchParams] = useSearchParams();
    let quizID = searchParams.get('quiz_id');
    let h = searchParams.get('h');

    return (
        <div>
            <QuizHeader />

            <div className="AppBody">
            <Container className="AppContainer">

                <Routes>
                    <Route path='*' element={<NotFound />} />

                    <Route
                        index 
                        element={
                            <QuizView quizID={quizID} />
                        }
                    />
                </Routes>

            </Container>
            </div>
        </div>
    );
}
