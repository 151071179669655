import React from 'react';
import { Table, Modal } from 'react-bootstrap';

import Loading from '../common/Loading.js';
import RenderRank from '../common/RankDisplay.js';

function SokobanPlayerListRow({player, puzzleView = false}) {
    return (
        <tr>
            <td>{RenderRank(player.rank)}</td>
            <td>
                <a href={'/sokoban/player/?player_id=' + player.id}>
                    {player.name}
                </a>
            </td>
            {puzzleView ? null : <td>{player.numSolved}</td>}
            {puzzleView ? null : <td>{player.score}</td>}
            {puzzleView ? <td>{player.steps}</td> : null}
        </tr>
    );
}

export default function SokobanLeaderboardView(
    {puzzleID = null, modal = false, updateTime = null, ...props}
) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [playerList, setPlayerList] = React.useState([]);
    const [isPuzzleView, setIsPuzzleView] = React.useState(false);
    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        setIsLoaded(false);
        fetch('/sokoban/fetch_leaderboard/' + (puzzleID ? '?puzzle_id=' + puzzleID : ''))
            .then(res => res.json())
            .then(
                  (payload) => {
                      setIsLoaded(true);
                      if (payload.puzzleName) {
                          setIsPuzzleView(true);
                      }
                      setPlayerList(payload.playerList)
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, [puzzleID, updateTime]);

    if (!isLoaded) {
        return <Loading />;
    }

    const renderTableHeader = () => {
        // Single puzzle stats
        if (isPuzzleView) {
            return (
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        <th>Steps</th>
                    </tr>
                </thead>
            );            
        }

        // This is the full leaderboard across all puzzles
        return (
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Name</th>
                    <th>Puzzles Solved</th>
                    <th>Sokoban Score</th>
                </tr>
            </thead>
        );
    };

    const renderTableBody = () => {
        return (
            <tbody>
                {playerList.map((player) =>
                    <SokobanPlayerListRow
                        key={'player_row_' + player.id}
                        player={player}
                        puzzleView={isPuzzleView}
                    />
                )}
            </tbody>
        );
    };

    if (modal) {
        return (
            <Modal
                {...props}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Top Scores</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped hover size="sm" className="sokobanListTable">
                        {renderTableHeader()}
                        {renderTableBody()}
                    </Table>                    
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <div>
            <h2 className="sokobanHeader">
                Top Scores
            </h2>
            <Table striped hover size="sm" className="sokobanListTable">
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        </div>
    );
}
