import React from 'react';
import { Container } from 'react-bootstrap';

import Loading from '../common/Loading.js';
import { postWithToken } from '../common/SecurityUtils.js';

import EventDetail from './EventDetail.js'


export default function EventView({eventID, tracker=''}) {
    // Event View Logging request
    React.useEffect(() => {
        let data = {
            event: eventID,
            t: tracker,
        };
        postWithToken('/event/logging/', data);
    }, []);

    return (
        <Container className="eventDetailContainer">
            <EventDetail eventID={eventID} showForm={true} />
        </Container>
    );
}
