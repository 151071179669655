import React from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { Clipboard } from 'react-bootstrap-icons';

import PuzzleToast from '../common/PuzzleToast.js';

import { jsonToClipboardItem } from '../common/CorgillogicalPuzzleMarkup.js';

export default function CorgillogicalCopyPuzzle({
    puzzleData,
}) {
    const [cachedPuzzleData, setCachedPuzzleData] = React.useState(null);
    const handleCopyPuzzleMarkup = () => {
        if (puzzleData.puzzle_type !== 'html') {
            return;
        }

        let clipboardData = cachedPuzzleData
        if (!clipboardData) {
            clipboardData = jsonToClipboardItem(puzzleData);
            setCachedPuzzleData(clipboardData);
        }

        navigator.clipboard.write(clipboardData);
    };

    return (
        <span>
            <a href="#" onClick={handleCopyPuzzleMarkup}>
                <Clipboard
                    className="puzzleHintIcon"
                    size={24}
                />
            </a>
        </span>
    );
}