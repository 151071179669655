
import React from 'react';
import { Alert, Button, Card, Col, Container, Form, Row} from 'react-bootstrap';

import Loading from '../common/Loading.js';
import { formatPrice } from '../common/Utils.js';

import ColorPatchFactory from './ColorPatch.js';
import SizeBox from './SizeBox.js';
import ShopApparelCart from './ShopApparelCart.js';

export function ShopApparelCard({apparel, onAdd}) {

    let variants = {};
    let firstColor = null;
    let firstSize = null;
    for (let variant of apparel) {
        let color = variant.metadata.color;
        
        if (!(color in variants)) {
            variants[color] = {};
        }

        let size = variant.metadata.size;
        variants[color][size] = variant;

        if (firstColor == null) {
            firstColor = color;
            firstSize = size;
        }
    }

    // active* is to account for the hover functionality. the other is the actual selected size/color.
    const [size, setSize] = React.useState(firstSize);
    const [activeSize, setActiveSize] = React.useState(size);
    const [color, setColor] = React.useState(firstColor);
    const [activeColor, setActiveColor] = React.useState(color);
    const [quantity, setQuantity] = React.useState(1);

    let variantSizes = variants[activeColor];
    let variant = variantSizes[activeSize];

    let coverPath = variant.image;
    let desc = variant.desc;
    let price = variant.price;
    let sku = variant.sku;
    let itemName = variant.name;

    const onEnterColor = (c) => {
        setActiveColor(c);
    }

    const onLeaveColor = () => {
        setActiveColor(color);
    }

    const onSelectColor = (c) => {
        setActiveColor(c);
        setColor(c);
    }

    const onEnterSize = (s) => {
        setActiveSize(s);
    }

    const onLeaveSize = () => {
        setActiveSize(size);
    }

    const onSelectSize = (s) => {
        setActiveSize(s);
        setSize(s);
    }

    return (
        <Card className="apparelCard">
            <Card.Body>
                <Row>
                    {coverPath && <Col sm="4"><img id={'img_' + sku} className="apparelImage" src={coverPath} /></Col>}
                    <Col sm="8">
                        <Row>
                            <Col><b>{itemName}</b></Col>
                        </Row>
                        <Row>
                            <Col><Form.Text muted>{desc}</Form.Text></Col>
                        </Row>
                        <Row>
                            <Col className="itemPrice">
                                {formatPrice(price)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="itemLabel" sm={4}> Color </Col>
                            <Col sm={6}>
                                {Object.keys(variants).map((v) => 
                                    <ColorPatchFactory
                                        activeColor={activeColor}
                                        colorid={v}
                                        onEnter={onEnterColor}
                                        onLeave={onLeaveColor}
                                        onSelect={onSelectColor}
                                    />)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="itemLabel" sm={4}>Size</Col>
                            <Col sm={6}>
                                {Object.keys(variantSizes).map((s) => 
                                    <SizeBox
                                        activeSize={activeSize}
                                        size={s}
                                        onEnter={onEnterSize}
                                        onLeave={onLeaveSize}
                                        onSelect={onSelectSize}
                                    />)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="itemLabel" sm={4}> Quantity </Col>
                            <Col sm={6}>
                                <Form.Select
                                    className="itemSelect"
                                    size="sm"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} />
                            <Col sm={6}>
                                <Button
                                    size="sm"
                                    onClick={() => onAdd(variant, quantity)}>
                                    Add To Cart
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Card.Body>
          </Card>

    );
}


export default function ShopApparelView({ products }) {
    const [cart, setCart] = React.useState({});
    const [flip, setFlip] = React.useState(true);
    const [completed, setCompleted] = React.useState(false);

    const updateCart = (newCart) => {
        setCart(newCart);
        setFlip(!flip);
    };

    if (!products) {
        return <div> There are currently no apparel for sale. </div>;
    }

    let productGroups = Object.values(products)
    const handleAddItem = (variant, quantity) => {
        cart[variant.sku] = {item: variant, quantity: quantity};
        updateCart(cart);
    };

    const handleEditItem = (sku, quantity) => {
        if (quantity == 0) {
            delete cart[sku];
            updateCart(cart);
            return;
        }

        // update quantity
        cart[sku].quantity = quantity;
        updateCart(cart);
    };

    const handleComplete = () => {
        setCompleted(true);
    };

    return (
        <>
            <Row xs={1} md={2} className="shopViewGroup">
                <Col>
                    <img 
                        variant="top"
                        src="/static/flatdog_logo_small.png"
                        className="apparelImage"
                    />
                </Col>
                <Col>
                    <Alert variant="primary">
                        Looking to get some Cocoa the Corgi Merch? Please make orders
                        {' '}
                        <a target="_blank" href="https://www.amazon.com/s/ref=bl_sl_s_ap_web_7141123011?ie=UTF8&amp;node=7141123011&amp;field-brandtextbin=Flatdog&_encoding=UTF8&tag=corgillogical-20&linkCode=ur2&linkId=5a3302fae379f7e5f87a3a4ed0765a8d&camp=1789&creative=9325">
                            on Amazon for the same price but with faster shipping!
                        </a>
                    </Alert>
                </Col>
            </Row>

        {!completed && 
        <Row xs={1} lg={2} className="shopViewGroup">
            {productGroups.map(
                x => <Col><ShopApparelCard apparel={x} onAdd={handleAddItem} /></Col>
            )}
        </Row>
        }

        <ShopApparelCart cart={cart} onEdit={handleEditItem} onComplete={handleComplete}/>
        </>
    );
}
