import React from "react";
import { Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { QuestionCircle } from 'react-bootstrap-icons';

import CommonNavbar from '../common/CommonNavbar.js';


export default function QuizHeader() {
    return (
        <>
            <CommonNavbar className="sokobanNavbar" title="Quiz">
                <Nav>
                </Nav>
            </CommonNavbar>
        </>
    );
}
